import { Injectable } from '@angular/core';
@Injectable()
export class StorageService {

  constructor() { }

  getData(key : string): string {
    return window.sessionStorage[key];
  }

  store(key: string,data : any) {
    window.sessionStorage.setItem(key, data);
  }

  remove(key : string){
    window.sessionStorage.removeItem(key);
  }

  getUser(key : string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  setUser(key: string,data : any){
    window.sessionStorage.setItem(key,JSON.stringify(data));
  }

  clear(){
    window.sessionStorage.clear();
  }

}
