import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DevLoginComponent } from './modules/dev-login/components/dev-login/dev-login.component';
import { ActivateUserComponent } from './modules/shared-components/components/activate-user/activate-user.component';
import { PageNotFoundComponent } from './modules/shared-components/components/page-not-found/page-not-found.component';
import { PermissionRouteGuard } from './services/permission-routeguard';
import { RedirectGuard } from './services/redirect-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  }, 
  {
    path: 'activate-user',
    component: ActivateUserComponent
  },
  {
    path: 'dashboard',   
    redirectTo: 'document-monitoring'
  },  
  {
    path: 'my-preferences',
    loadChildren: () => import('./modules/my-preferences/my-preferences.module').then(m => m.MyPreferencesModule)
  },
  {
    path: 'document-monitoring',
    loadChildren: () => import('./modules/document-monitoring/document-monitoring.module').then(m => m.DocumentMonitoringModule)
  }, 
  {
    path: 'profile',
    loadChildren: () => import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule),
    canActivate: [PermissionRouteGuard],
  },
  {
    path: 'login',
    component: RedirectGuard,
    canActivate: [RedirectGuard],
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/login"
    }
  },
  {
    path: 'logout',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/logout"
    }
  },
  {
    path: 'apps',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'login' + environment.DOMAIN + "/apps"
    }
  },
  {
    path: 'connect',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: environment.SUB_DOMAIN + 'connect' + environment.DOMAIN + '/industry-analytics?tab=true'
    }
  },
  {
    path: "BBF65054CC9C2C8CB6692B2A1648FD8C",
    component: DevLoginComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }
