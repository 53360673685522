<div>

    <ng-template #simpleText>
        {{text}}
    </ng-template>

    <div *ngIf="text!=''">
        <div class="tooltip-conatiner {{placement}}">
            <ng-container *ngTemplateOutlet="simpleText">

            </ng-container>
        </div>
    </div>
    <div *ngIf="text=='' && contentTemplate!=null">
        <div class="tooltip-conatiner {{placement}}">
            <ng-container *ngTemplateOutlet="contentTemplate;context:{contentValue: dataContent}">

            </ng-container>
        </div>
    </div>
    <!-- <div>
            <ng-container *ngTemplateOutlet="contentTemplate || simpleText;context:{contentValue: dataContent}">

            </ng-container>
        </div>
        <ng-container *ngTemplateOutlet="contentTemplate || simpleText;context:{contentValue: dataContent}">

        </ng-container> -->

    <!-- <ng-container *ngIf="!dataContent">
            <ng-container *ngTemplateOutlet="contentTemplate || simpleText">

            </ng-container>
        </ng-container> -->

</div>