import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommentsService } from '../comments.service';

@Component({
  selector: 'app-comments-overlay',
  templateUrl: './comments-overlay.component.html',
  styleUrls: ['./comments-overlay.component.scss']
})
export class CommentsOverlayComponent implements OnInit {

  validComment: boolean = false;
  searchValue: string;
  commentData: any = [];
  orgId: any;

  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  constructor(private commentService: CommentsService, public dialogRef: MatDialogRef<CommentsOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.getListOfComments();
    // this.orgId = this.sessionStorage.getUser("currentUser").orgId;
    this.scrollToBottom();
    if (this.data.commentValue) {
      this.validComment = true;
      this.searchValue = this.data.commentValue;
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getListOfComments() {
    if (this.data.docTypeCode && this.data.docId) {
      this.commentService.getListOfComments(this.data.docTypeCode, this.data.docId).subscribe(res => {
        this.commentData = res;
        this.data.rowData = 0;
      });
    }
  }
  onPaste(event) {
    this.setCommentValue(event);
  }
  setCommentValue(value) {
    if (/\S/.test(value) && value.length > 0) {
      this.validComment = true;
    } else {
      this.validComment = false;
    }
    this.data.setComment.emit(value);
  }

  addComment(docTypeCode, docId, event) {
    if (docTypeCode && docId) {
      if (/\S/.test(this.searchValue) && this.searchValue.length > 0) {
        this.searchValue = this.searchValue.replace(/^\s+/g, "");
        this.commentService.addComment(this.searchValue, docTypeCode, docId).subscribe((res) => {
          if (res.status === 200) {
            this.getListOfComments();
            this.searchValue = '';
            this.data.commentValue = '';
            this.validComment = false;
            this.data.setComment.emit('');
          }
        },
          error => {
            if (error.status === 200) {
              this.getListOfComments();
              this.searchValue = '';
              this.data.commentValue = '';
              this.validComment = false;
              this.data.setComment.emit('');
            }
          }
        );
        event.stopPropagation();
      }
    }

  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

}

export interface DialogData {
  docId: any
  docTypeCode: any
  setComment: any
  rowData: any
  showSendButton: any
  commentValue: string
}