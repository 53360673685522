<side-nav>
    <div class="navBarHeight" sidenavitem>
        <!-- <a mat-list-item *ngIf="permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view"
            routerLink="/company/account-setting" routerLinkActive="active"
            title="{{'NAVBAR.LABEL.ACCOUNT_PROFILE' | translate}}">
            <span class="sidebar-icon icon-account_settings"></span>
            {{ 'NAVBAR.LABEL.ACCOUNT_PROFILE' | translate }}
        </a>
        <a mat-list-item *ngIf="permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view" routerLink="/user/user-list"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.USERS' | translate}}">
            <span class="sidebar-icon icon-task_centre"></span>
            {{ 'NAVBAR.LABEL.USERS' | translate }}
        </a>
        <a mat-list-item *ngIf="permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view" routerLink="/business-units"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.BUSINESS_UNITS' | translate}}">
            <span class="sidebar-icon icon2-business-unit"></span>
            {{ 'NAVBAR.LABEL.BUSINESS_UNITS' | translate }}
        </a>
        <a mat-list-item *ngIf="permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view" routerLink="/catalog"
            routerLinkActive="active" title="{{'NAVBAR.LABEL.CATALOG' | translate}}">
            <span class="sidebar-icon icon-catalog"></span>
            {{ 'NAVBAR.LABEL.CATALOG' | translate }}
        </a> -->
        <div class="nav-new-bar">
            <div *ngIf="navigationScrollButton=='up' || navigationScrollButton=='both'" class="up-btn"
                (click)="handelScrollClick('up')">
                <img src="../../../../../assets/images/up_double.png" alt="">
            </div>
            <div class="nav-menu-list" id="sidebar" (scroll)="scrollFunction()">
                <!-- (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce -->
                <a mat-list-item
                    *ngIf="(permissions?.CONNECT?.MODULES?.ADMIN?.actions?.view) || (permissions?.CONNECT?.MODULES?.DOC_MONITORING?.actions?.view)"
                    routerLink="/document-monitoring" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate}}">
                    <!-- <span class="sidebar-icon icons-documentmonitor"></span> -->
                    <div class="sprite-container monitoring">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.DOCUMENT_MONITORING' | translate }}</span>
                </a>
                <a mat-list-item routerLink="/my-preferences" routerLinkActive="active"
                    title="{{'NAVBAR.LABEL.MY_PREFERENCES' | translate}}">
                    <!-- <span class="sidebar-icon icon2-settings"></span> -->
                    <div class="sprite-container preferences">
                        <div class="sprite-image"></div>
                    </div>
                    <span class="nav-name">{{ 'NAVBAR.LABEL.MY_PREFERENCES' | translate }}</span>
                </a>
            </div>
            <div *ngIf="navigationScrollButton=='down' || navigationScrollButton=='both'" class="down-btn"
                (click)="handelScrollClick('down')">
                <img src="../../../../../assets/images/down_double.png" alt="">
            </div>
        </div>
        
        <div class="connect-link">
            <a mat-list-item routerLink="/connect" target="_blank" routerLinkActive="active"
                title="{{'NAVBAR.LABEL.CONNECT' | translate}}" class="connect-link">
                <!-- <span class="sidebar-icon icon-Group"></span> -->
                <img src="../../../../../assets/images/Group.png">
                <span class="nav-name">{{'NAVBAR.LABEL.CONNECT' | translate}}</span>
            </a>
        </div>
    </div>
</side-nav>