import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DocTypes } from '../../constant';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {


  private headers = new HttpHeaders();

  constructor(public http: HttpClient) {
    this.headers.set('Content-Type', 'application/json');
  }

  getListOfComments(docTypeCode, docId): Observable<any> {

    let url = environment.BASE_API_URL + this.getApiCallUrl(docTypeCode) + "/comment/list/" + docId + "/" + docTypeCode;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addComment(comment, docTypeCode, docId): Observable<any> {
    let url = environment.BASE_API_URL + this.getApiCallUrl(docTypeCode) + "/comment" + this.getApiUrlEnd(docTypeCode);
    let commentObj: any;
    commentObj = {
      "trxDocId": docId,
      "trxDocTypeCode": docTypeCode,
      "comment": comment,
    }

    return this.http.post<any>(url, commentObj, { headers: this.headers, observe: 'response' }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  extractData(response) {
    return response.body;
  }

  getApiCallUrl(docTypeCode): string {
    switch (docTypeCode) {
      case DocTypes.INVOICE:
      case DocTypes.INVOICELINE:
      case DocTypes.PO:
      case DocTypes.POLINE:
        return "order";
      case DocTypes.LOAD:
        return "logistics"
      case DocTypes.INTLORG:
        return "admin/api"
      default:
        return "order";
    }
  }

  getApiUrlEnd(docTypeCode): string {
    switch (docTypeCode) {
      case DocTypes.INVOICE:
      case DocTypes.INVOICELINE:
      case DocTypes.PO:
      case DocTypes.POLINE:
        return "";
      case DocTypes.LOAD:
        return ""
      case DocTypes.INTLORG:
        return "/add"
      default:
        return "";
    }
  }

  extractData1(response) {
    return response;
  }
}
