import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';
import { LayoutService } from 'src/app/services/layout.service';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'side-toolbar',
  templateUrl: './side-toolbar.component.html',
  styleUrls: ['./side-toolbar.component.scss']
})
export class SideToolbarComponent implements OnInit {

  sideNav = [];
  adminMenus: any = [
    /* { title: this.translate.instant("NAVBAR.LABEL.DASHBOARD"), route: '/main-dashboard/dashboard', img: 'icon-dashboard' },
    { title: this.translate.instant("NAVBAR.LABEL.TASK_CENTER"), route: '/task-center/task-center-list', img: 'icon-task_centre' }, */
    { title: this.translate.instant("NAVBAR.LABEL.ACCOUNT_PROFILE"), route: '/company/account-setting', img: 'icon-account_settings' },
    { title: this.translate.instant("NAVBAR.LABEL.USERS"), route: '/user/user-list', img: 'icon-task_centre' },
    { title: this.translate.instant("NAVBAR.LABEL.BUSINESS_UNITS"), route: '/business-units', img: 'icon2-business-unit fs-24' },
    { title: this.translate.instant("NAVBAR.LABEL.CATALOG"), route: '/catalog', img: 'icon-catalog' },
    // { title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' },
    { title: this.translate.instant("NAVBAR.LABEL.DOCUMENT_MONITORING"), route: '/document-monitoring', img: 'icons-documentmonitor' }
  ];
  applicationList: any[] = [];
  navigationScrollButton = "none";

  constructor(private layoutService: LayoutService, private sessionStorage: StorageService, private translate: TranslateService, private auth: OneAuthService, private route: ActivatedRoute, private router: Router, private cdr: ChangeDetectorRef) { }
  public permissions: any;
  ngOnInit() {
    this.permissions = this.auth.user.permissions;
    // if (this.auth.user.id == undefined) {
    //   this.auth.LoadAuth().then(() => {
    //     if (this.auth.user["permissions"]) {
    //       this.applicationList = this.auth.user["permissions"];
    //       // this.setPermission(this.applicationList);
    //       this.setnavBar(this.applicationList)
    //     } else {
    //       this.layoutService.getUserModulePermission().subscribe(resp => {
    //         if (resp) {
    //           // this.sessionStorage.setUser('permissions', resp);
    //           this.setnavBar(resp);
    //         } else {
    //           this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' });
    //         }
    //       })
    //     }
    //   });
    // } else {
    //   this.setnavBar(this.auth.user.permissions);
    // }

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.setNavigationScrollButton();
  }
  // setnavBar(resp) {
  //   // this.sideNav = this.carrierUserMenus;
  //   let connectApp = resp.CONNECT;
  //   console.log(connectApp);
  //   if (connectApp) {
  //     if (connectApp.MODULES.ADMIN && connectApp.MODULES.ADMIN['actions'] && connectApp.MODULES.ADMIN['actions'].view) {
  //       this.sideNav = this.adminMenus;
  //     } else if (connectApp.MODULES.DOC_MONITORING && connectApp.MODULES.DOC_MONITORING.actions && connectApp.MODULES.DOC_MONITORING.actions.view) {
  //       this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.DOCUMENT_MONITORING"), route: '/document-monitoring', img: 'icons-documentmonitor' });
  //     }
  //     this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' });
  //   } else {
  //     this.sideNav.push({ title: this.translate.instant("NAVBAR.LABEL.MY_PREFERENCES"), route: '/my-preferences', img: 'icon2-settings' });
  //   }
  // }

  // checkActive(link) {
  //   let str = link.route.split("/")
  //   if (str.filter(item => item === this.route.snapshot.children[0].routeConfig.path).length > 0) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  scrollFunction() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollTop > 20) ? ((sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight - 60 >= 1) ? "both" : "up") : "down";
    // this.scrollPostion=(sidebar.scrollHeight - sidebar.scrollTop - sidebar.clientHeight-60 >= 1) && (sidebar.scrollTop > 20);
  }

  //PMB-6554(RD) check scroll exits or not
  setNavigationScrollButton() {
    var sidebar = document.getElementById('sidebar');
    this.navigationScrollButton = (sidebar.scrollHeight > sidebar.clientHeight) ? "down" : "none";

  }

  //PMB-6554(RD) set flag for up and down buttons
  handelScrollClick(direction) {
    var sidebar = document.getElementById('sidebar');
    sidebar.scrollTop = direction == "up" ? 0 : sidebar.clientHeight;
  }
}
