import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OneAuthService } from 'one-auth';
import { IMAGES } from '../../../../../environments/config.all';
import { AppToolbarService } from '../../services/app-toolbar.service';

@Component({
  selector: 'header-toolbar',
  templateUrl: './header-toolbar.component.html',
  styleUrls: ['./header-toolbar.component.scss']
})
export class HeaderToolbarComponent implements OnInit {
  public IMAGES = IMAGES;
  public user;
  public subApps;
  localUrl: any;
  avtarName: any;

  constructor(public auth: OneAuthService, private router: Router, private appToolbarService: AppToolbarService) { }

  ngOnInit() {
    // if (this.auth.user == undefined || this.auth.user.info.id === undefined) {
    //   this.auth.loadAuthFromStorage().then(user => {
    //     this.auth.getTokenFromStorage().subscribe((token) => {
    //       this.auth.checkUserAuthValidity().subscribe((res) => {
    //         if (this.auth.user !== undefined) {
    //           this.user = this.auth.user;
    //         } else {
    //           // this.router.navigate(['apps']);
    //         }
    //         // clearTimeout(this.waiting);
    //       }, (err) => { });
    //     });
    //   });
    // } else {
    //   this.user = this.auth.user;
    // }
    this.user = this.auth.user;
    this.subApps = this.auth.user.subApps;
    this.setUserImage();
    // (RD)PMB-6554 New header and navigation menu bar for Procurant Commerce
    let name = this.user.info.firstName.split(" ")[0] +' '+this.user.info.lastName.split(" ")[0];
    this.avtarName = name.split(" ").map((n)=>n[0]).join("").toUpperCase();
  }

  setUserImage() {
    if (this.auth.user.info && this.auth.user.info.fileName != null && this.auth.user.info.objectId != null) {
      this.appToolbarService.getProfileImage(this.auth.user.info.objectId).subscribe(r => {
        if (r) {
          this.auth.thumbnil = null;
          this.auth.thumbnil = "data:image/" + this.auth.user.info.fileName.split('.').pop() + ";base64," + r
        }
      });
    }
  }
  openAdmin() {
    //window.location.href = environment.BASE_API_URL + "common-app/#/app/company/account-setting"
  }
  /* temp logout */
  /*    logout(){
       sessionStorage.clear();
       localStorage.clear();
     } */
  logout() {
    this.auth.logoutUser().subscribe(res => {
      sessionStorage.clear();
      sessionStorage.setItem('lang', 'en_US');
      if (res.status === 200) {
        this.auth.clearSession();
        this.router.navigate(['logout']);
      }
    });
  }
}