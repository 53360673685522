import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { DevLoginComponent } from './components/dev-login/dev-login.component';

@NgModule({
    declarations: [DevLoginComponent],
    imports: [
        CommonModule,
        MaterialUiModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    providers: [
        
    ],
    exports: []
})
export class DevLoginModule { }
