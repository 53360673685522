import * as moment_ from 'moment';
import "moment-timezone";
const moment = moment_["default"];

export const RegExpression = Object.freeze({
	SPECIAL_CHARACTOR: '^.*[^ \\t].*$',
	EMAIL: '(?=(.*[0-9]))(?=.*[!@#$*%^&?_+=~`|:;"<>,.\\-])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}',
	PASSWORD: '^\\w+([\.-]?\\w+)*@\\w+([\.-]?\\w+)*(\\.\\w{2,3})+$',
	NUMBER: '^[0-9]+$',
	DECIMAL: '^[0-9]+(\.^|[^,][0-9]{1,4})?$', // upto 4 dicimal
	PHONE: '^[0-9]{1,10}$',// 10 digit number,
	ALFA_NUMERIC: '^[A-Za-z0-9- ]+$'
});

export const DocTypes = Object.freeze({
	PO: 'PO',
	POLINE: 'PO_LINE',
	INVOICE: 'INVOICE',
	INVOICELINE: 'INVOICE_LINE',
	LOAD: 'LOAD',
	INTLORG: 'INTL_ORGANIZATION'
});

export const rangeStatus = Object.freeze({
	RANGE_EXHAUSTED: 'RANGE_EXHAUSTED',
	RANGE_NOT_AVAILABLE: 'RANGE_NOT_AVAILABLE',
	SYSTEM_ERROR: 'SYSTEM_ERROR',
	INPROCESS: 'INPROCESS',
	NO_ERROR: 'NO_ERROR',
	USER_DEFINED:'USER_DEFINED',
	SYSTEM_GENERATED:'SYSTEM_GENERATED'
});

export const ActionButtons = Object.freeze({
	ACTION_COMMENT: 'comment',
	ACTION_EDIT: 'edit',
	ACTION_DELETE: 'delete',
	ACTION_INFO: 'info',
	ACTION_DOWNLOAD: 'download',
});

export const PAYMENT_TERMS = Object.freeze({
	INVOICE_DATE: "INVOICE_DATE",
	RECEIVED_DATE: "RECEIVED_DATE",
	SHIPPED_DATE: "SHIPPED_DATE"
});

export const CLAIMS = Object.freeze({
	SUPPLIER: "SUPPLIER",
	CARRIER: "CARRIER",
	THIRD_PARTY: "THIRD_PARTY",
	APPROVED:'Approved',
	CANCELLED:'Cancelled'

});

//Used for Action in OrderDetails Page for Both Buyer and Vendor
export const PO_ACTION = Object.freeze({
	CANCEL: 'Cancel',//same is used for line cancel
	SUBMITORDER: 'submitOrder',
	RECEIVED: 'received',
	SHIPORDER: 'shipOrder',
	CANCELLINE: 'CancelLine',
	ACKNOWLEDGE: 'Acknowledge',
	ADDLINE: 'AddLine',
	MOVELINE: 'MoveLine',
	COPYPO: 'CopyPO',
	NEW: 'New',
	MODIFIED: 'Modified',
	SHIPPED: 'Shipped',
	TRACEINFO: 'TraceInfo',
	CHANGESUPPLIER: 'ChangeSupplier',
	INVOICED: 'Invoiced',
	RECEIVED_MISMATCH: 'Received Mismatch',
	RECEIVED_ADJUSTED: 'ReceivedAdjusted',
	UNLOADED_ORDER: 'Unloaded Order',
	UNLOADED_ORDERS: 'unloaded-orders',
	DELAYED: 'Delayed'
});

export const STATUS = Object.freeze({
	ACTIVE: 'Active',
	INACTIVE: 'Inactive',
});

//Used for Action in LoadDetails Page
export const LOAD_ACTION = Object.freeze({
	MOVEORDER: 'moveOrder',
	REMOVEORDER: 'removeOrder',
	CANCELLOAD: 'cancelLoad',
	SENDLOAD: 'sendLoad',
	ADDORDER: 'addOrder',
});

//Used for Action in LoadDetails Page for Both Buyer and Vendor
export const LOAD_STATUS = Object.freeze({
	CANCELLED: 'Cancelled',
	RECEIVED: 'Received',
	SENT: 'Sent',
});

//Used for Action in LoadDetails Page for Both Buyer and Vendor
export const ROUTING = Object.freeze({
	FOB_CARRIER: 'FOB Carrier',
	BACKHAUL: 'Backhaul',
	DELIVERED: 'Delivered'
});

//Used for SUB_STATUS in OrderDetails Page for Both Buyer and Vendor
export const SUB_STATUS = Object.freeze({
	NEW: 'New',
	ACKNOWLEDGED: 'Acknowledged',
	BUYER_MODIFIED: 'Buyer Modified',
	SUPPLIER_MODIFIED: 'Supplier Modified',
	BUYER_SUPPLIER_MODIFIED: 'Buyer Supplier Modified',
	SHIPPED: 'Shipped',
	SUPPLIER_CANCELLED: 'Supplier Cancelled',
	BUYER_CANCELLED: 'Buyer Cancelled',
	RECEIVED: 'Received',
	CANCELLED: 'Cancelled'
});

//Demand status
export const DEMAND_STATUS = Object.freeze({
	OPEN: 'Open',
	FULFILLED: 'Fulfilled',
	CANCELLED: 'Cancelled',
});

//Used for Modification done by which party Buyer/Supplier/NoModification
export enum PO_MODFLAG {
	NOMODIFICATION = 0,
	BUYER = 1,
	SUPPLIER = 2
}

export enum PO_STATUS {
	New = 1,
	Modified,
	Acknowledged,
	Shipped,
	Shipped_Modified,
	Received,
	Cancelled
}

export enum INVOICE_STATUS {
	Draft = 1,
	Invoiced
}

export enum PAYMENT_TERMS_FROM_DATE {
	INVOICE_DATE = "Invoice Date",
	SHIPPED_DATE = "Shipped Date",
	RECEIVED_DATE = "Received Date"
}

export enum APPLY_BY_LOOKUP {
	PER_PACKTYPE = "Per Packtype",
	PER_WEIGHT = "Per Weight (per lb/per kg)",
	FLAT = "Flat"
}

export enum PRORATE_BY_LOOKUP {
	BY_PALLETS = "By Pallets",
	BY_PACKTYPE = "By Packtype (per case)",
	BY_VOLUME = "By Volume (per Cu. ft/per Cu mt.)",
	BY_WEIGHT = "By Weight (per lb/per kg)"
}

export const SUBSCRIBED_APPS = Object.freeze({
	BUYER: 'BUYER',
	SUPPLIER: 'SUPPLIER',
	CARRIER: 'CARRIER'
});

export const USER_NAV_ACTION = Object.freeze({
	PROFILE: 'Profile',
	LOGOUT: 'Logout'
});

export const INVALID_DATE_MSG = Object.freeze({
	INVALID_DATE: 'Invalid date'
});

export const SESSIONREMEMBER_KEYS = Object.freeze({
	ORDER_LIST: "orderList",
	LOADORDER_LIST: "loadOrderList",
	INVOICE_LIST: "invoiceList",
	LOAD_LIST: "loadList",
	UNLOADED_LIST: "unLoadedList",
	CLAIM_LIST: "claimList",
	DEMANDSENT_LIST: "demandSentList",
	DEMANDRECV_LIST: "demandRecvList",
	RECEIVEDORDERCLAIMS: "ReceivedOrderListComponent"
});
export const EDI_VALUES = Object.freeze({
	BUYER: "BUYER",
	CARRIER: "CARRIER",
	SUPPLIER: "SUPPLIER",
	INBOUND: "INBOUND",
	OUTBOUND: "OUTBOUND",
	TODAY: 'Today',
	LAST_WEEk: 'Last_Week',
	LAST_TWO_WEEK: 'Last_Two_Weeks',
	LAST_MONTH: 'Last_Month'

});
export const PRICE_PER = Object.freeze({
	WEIGHT: "Weight",
	PACK_TYPE: "Pack Type"
});
export const WEIGHT_UOM = Object.freeze({
	KG: "KG",
	LB: "LB"
});
export const UNIT_CONVERSION = Object.freeze({
	KG_TO_LB: 2.2046
});
export const ORDER_REPORT = Object.freeze({
	SHIP_DATE: "Ship Date",
	DELIVERY_DATE: "Delivery Date",
	APP_CODE_BUYER:"BUYER",
	ORDER_MANAGEMENT:"ORDER_MANAGEMENT",
	REPORT:"REPORT",
	ORDER_DETAIL:"ORDER_DETAIL",
	EDIT:"EDIT",
	PARTNER_APPLICATION:"SUPPLIER"
});
export const MY_PREFERENCES = Object.freeze({
	BUYER: 'BUYER',
	SUPPLIER: 'SUPPLIER',
	LINK:"LINK",
	CONNECT:"CONNECT"
});
export const MY_VIEW = Object.freeze({
	CREATEDATE: "createDate",
	DELIVERYDATE: "deliveryDate",
	SHIPDATE:"shipDate",
	FOBCARRIER:"FOB Carrier",
	BACKHAUL:"Backhaul",
	BUYER:"BUYER",
	ORDER_MANAGEMENT:"ORDER_MANAGEMENT",
	VIEW:"VIEW",
	CREATE_LOAD:"CREATE_LOAD",
	LOAD_CREATE:"loadCreate",
	ORDER_DELIVERY:"orderDelivery",
	ORDER_SHIPPED:"orderShipped",
});

export class constant {
	public static replaceErrorMsgValue(errorMsg?, ...values: string[]): string {
		let regex = /<<(.+?)>>/g;
		let replacetext = errorMsg.match(regex);
		replacetext.forEach((element, index) => {
			errorMsg = errorMsg.replace(element, values[index]);
		});
		return errorMsg;
	}

	public static handleError(error) {
		let errorMessage = '';
		if (error.headers) {
			if (error.headers.get('Error')) {
				errorMessage = error.headers.get('Error')
			}
		}
		return errorMessage;
	}

	//Use this in case to send time as 00:00:00 considering UTC
	public static convertDateToUTC(newdate, removeTime?: number, formats?: number) {
		let date = moment(newdate).add({ hours: moment().hour(), minutes: moment().minute(), seconds: moment().second() })
		if (removeTime == 1) {
			let isoDate = date.toISOString();
			let indexT = isoDate.indexOf('T');
			let substring = isoDate.substring(0, indexT);
			substring += "T00:00:00.000+0000";
			return substring;
		}
		else if (removeTime == 2) {
			date = moment(newdate);
			let isoDate = date.toISOString();
			let indexT = isoDate.indexOf('.0');
			let substring = isoDate.substring(0, indexT);
			substring += "+0000";
			return substring;
		}
		else if (removeTime == 3) {
			let datestring = newdate.replace("T", " ");
			let indexP = datestring.indexOf('.0');
			let zone = datestring.substring(0, indexP);
			zone += ".0"
			date = moment(zone);
			let isoDate = date.toISOString();
			let indexT = isoDate.indexOf('.0');
			let substring = isoDate.substring(0, indexT);
			substring += "+0000";
			return substring;
		}
		else {
			let isoDate = date.toISOString();
			return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZZ')
		}
	}
}