import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonDialogComponent } from '../components/dialog-box/common-dialog/common-dialog.component';
import { ConfirmDialogComponent } from '../components/dialog-box/confirm-dialog/confirm-dialog.component';

@Injectable()
export class DialogBoxService {

    dialogRef: MatDialogRef<any>;
    data: any;
    isSubmited: boolean = false;

    constructor(public dialog: MatDialog) { }

    // Confirmation dialog box
    openConfirmDialog(message, buttonOkText, buttonCancelText, width?, height?, hideCancelBtn = false) {
        return new Promise((resolve, reject) => {
            buttonOkText = buttonOkText || 'BUTTON.YES';
            buttonCancelText = buttonCancelText || 'BUTTON.NO';
            let dialogWidth: string = '250px';
            let dialogHeight: string = '250px';

            if (width)
                dialogWidth = width.toString() + 'px';
            if (height)
                dialogHeight = height.toString() + 'px';

            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: dialogWidth,
                height: dialogHeight,
                data: { message: message, buttonOkText: buttonOkText, buttonCancelText: buttonCancelText, hideCancel: hideCancelBtn },
                disableClose: true //can not close dialog on escape button
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (result.result === true) {
                        resolve(true);
                    }
                    else { reject(); }
                }
                else { reject(); }
            });
        });
    }


    // To open any component in dialog box
    openCommonDialog(component, title, rawData?, width?, height?)/* : Promise<boolean> */ {
        return this.dialogRef = this.dialog.open(CommonDialogComponent, {
            width: width,
            height: height,
            data: { component: component, title: title, dataDetails: rawData || undefined },
            disableClose: true //can not close dialog on escape button
        });
    }

    closeDialog(cloaseData?) {
        this.dialogRef.close(cloaseData);
    }

    submited() {
        this.isSubmited = true;
    }

}
