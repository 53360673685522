import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { URLS } from 'src/environments/config.url';

@Injectable({
  providedIn: 'root'
})
export class AppToolbarService {
  public headers: HttpHeaders;
  public flagReminder: boolean = false;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    // Add Content-Type be default
    this.headers.set('Content-Type', 'application/json');
  }



  getProfileImage(objectId?): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/org/buyer/supplier-codes/"+param).get()
    let url = URLS.GetProfileImage + objectId;
    return this.http.get<any>(url).pipe(
      map((res) => {
        return res.content;
      })
    );
  }

}
