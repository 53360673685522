<div fxLayout="row" [formGroup]="parentForm">
  <mat-form-field fxFlex="100%">
    <mat-select placeholder="{{placeholder}}" formControlName="{{field}}" multiple
      (selectionChange)="toggleSelect($event)" disableOptionCentering [disabled]="disabled">
      <mat-checkbox *ngIf="isShowAllOption" (change)="masterToggle($event)" class="mat-option" [checked]="allSelected"
        [indeterminate]="selectedList.length > 0 && !allSelected">{{'DETAIL_PAGE.LABEL.ALL' | translate}}
      </mat-checkbox>
      <mat-select-trigger *ngIf="allSelected"> {{'DETAIL_PAGE.LABEL.ALL' | translate}} </mat-select-trigger>
      <mat-option *ngFor="let list of listData;let i=index" [value]="valuetoBind ? list[valuetoBind] : list">
        {{list[valuetoShow]}}</mat-option>
    </mat-select>
    <mat-error *ngIf="parentForm['controls'][field].touched && parentForm['controls'][field].hasError('required')">
      {{ requiredError | translate }}
    </mat-error>
  </mat-form-field>
</div>