import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { OneAuthService } from "one-auth";


@Injectable({
    providedIn: 'root'
})
export class InitLoaderService {
    constructor(private auth: OneAuthService, private route: Router) {

    }
    load() {
        this.auth.LoadInitInfo();
        // let i = this.auth.user.subApps.filter(t => t.applicationCode == "BUYER")
        // console.log("in load init");
        // console.log(this.auth.user)
        // if (!i) {
        //     this.route.navigate(['apps']);
        // }

    }
}