import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URLS } from 'src/environments/config.url';


@Injectable({
    providedIn: 'root'
})
export class LayoutService {
    public flagDemand = 0;
    public flagReminder: boolean = false;
    public demandCreateFlag: boolean = false;
    public permissions: any;
    public accessToken: any;
    private headers = new HttpHeaders();
    constructor(public http: HttpClient, @Inject('env') private env) {
        this.headers.set('Content-Type', 'application/json');
    }
    getUserDataFromToken() {
        if (this.accessToken.token) {
            return JSON.parse(window.atob(this.accessToken.token.split('.')[1]));
        }
    }



    getAppModulePermission(): Observable<any> {
        // return this.$http.baseUrlContext.url('/auth/api/user/app-module-permissions').get()
        let url = this.env.BASE_API_URL + URLS.AppModulePermissions;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getOrgBussinessType(): Observable<any> {
        // var listOfOrgBussinessType = this.$http.baseUrlContext.url('/admin/api/org/public/business-types').get()
        let url = this.env.BASE_API_URL + URLS.GetOrgBusinessTypeAPI
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return listOfOrgBussinessType;
    }

    getCountryList(): Observable<any> {
        // var listOfCountry = this.$http.baseUrlContext.url('/admin/api/address/public/countries').get()
        let url = this.env.BASE_API_URL + URLS.CountryListAPI;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return listOfCountry;
    }

    getStatesListByCountryCode(countryCode): Observable<any> {
        // var listOfStates = this.$http.baseUrlContext.url('/admin/api/address/public/state-list/' + countryCode).get()
        let url = this.env.BASE_API_URL + URLS.StateListAPI + countryCode;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return listOfStates;
    }

    isOrgnizationNameExist(orgName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/public/exists-by-name/' + orgName).get()
        let url = this.env.BASE_API_URL + URLS.OrgNameExistsAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return isExist;
    }

    isBusinessEmailExist(orgName): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/public/exists-by-email/' + orgName).get()
        let url = this.env.BASE_API_URL + URLS.BusinessEmailExistsAPI + orgName;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return isExist;
    }

    isDunsNumberExist(duns): Observable<any> {
        // var isExist = this.$http.baseUrlContext.url('/admin/api/org/duns-exists-with-otherOrg/' + duns).get()
        let url = this.env.BASE_API_URL + URLS.DunsNumberExistsAPI + duns;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return isExist;
    }

    postCompany(company): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/org/public/create").post(company)
        let url = this.env.BASE_API_URL + URLS.AddCompanyAPI;
        return this.http.post<any>(url, company, { headers: this.headers }).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getCompanyDetails(): Observable<any> {
        // var listOfStates = this.$http.baseUrlContext.url('/admin/api/org/fetchOrg').get()
        let url = this.env.BASE_API_URL + URLS.GetCompanyDetailsAPI;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                //return this.extractData(res);
            })
        );
        // return listOfStates;
    }

    updateCompany(company): Observable<any> {
        // return this.$http.baseUrlContext.url("/admin/api/org/update").put(company)
        let url = this.env.BASE_API_URL + URLS.UpdateCompanyAPI;
        return this.http.post<any>(url, company, { headers: this.headers }).pipe(
            map((res: Response) => {
                return res;
            })
        );
    }

    getLanguages(): Observable<any> {
        // var languages = this.$http.baseUrlContext.url('/admin/api/org/languages').get()
        let url = this.env.BASE_API_URL + URLS.GetLanguagesAPI;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return languages;
    }

    getCurrency(): Observable<any> {
        // var currency = this.$http.baseUrlContext.url('/admin/api/org/currencies').get()
        let url = this.env.BASE_API_URL + URLS.GetCurrencyAPI;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return currency;
    }

    getTimeZones(): Observable<any> {
        // var timeZones = this.$http.baseUrlContext.url('/admin/api/org/available-timezones').get()
        let url = this.env.BASE_API_URL + URLS.GetTimeZoneAPI;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return timeZones;
    }

    getDateFormates(): Observable<any> {
        // var dateFormates = this.$http.baseUrlContext.url('/admin/api/org/available-dateFormats').get()
        let url = this.env.BASE_API_URL + URLS.GetDateFormatsAPI;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
        // return dateFormates;
    }



    updateReminder(reminder): Observable<any> {
        // return this.$http.baseUrlContext.url("/order/reminder/update").post(reminder)
        let url = this.env.BASE_API_URL + URLS.UpdateRemindersAPI;
        return this.http.post<any>(url, reminder, { headers: this.headers }).pipe(
            map(res => {
                return res;
                //return this.extractData(res);
            })
        );
    }

    getRemindersList(fromDate, toDate): Observable<any> {
        let reqData = {
            "fromDate": fromDate,
            "toDate": toDate
        }
        // return this.$http.baseUrlContext.url("/order/reminder/list").post(reqData)
        let url = this.env.BASE_API_URL + URLS.GetRemindersListAPI;
        return this.http.post<any>(url, reqData, { headers: this.headers }).pipe(
            map(res => {
                return res;
                // return this.extractData(res);
            })
        );
    }
    deleteDismissReminder(reqData) {
        // return this.$http.baseUrlContext.url("/order/reminder/remove").delete(reqData)
        let url = this.env.BASE_API_URL + URLS.DeleteDismissReminderAPI;
        return this.http.request('DELETE', url, { headers: this.headers, body: reqData, observe: 'response' }).pipe(
            map(res => {
                return res;
            }));
    }
    enableReminder(reqData) {
        let url = this.env.BASE_API_URL + URLS.EnableReminderAPI;
        return this.http.post<any>(url, reqData, { headers: this.headers }).pipe(
            map((res) => {
                return res;
            }));
    }

    extractData(response) {
        return response;
    }
    getUserModulePermission(): Observable<any> {
        return this.http.get<any>(URLS.UserPermissionAPI).pipe(
            map((res) => {
                return res;
            })
        );
    }

    getUserByToken(token): Observable<any> {
        // var userDetails = this.$http.baseUrlContext.url('/admin/api/user/public/validate-token/' + token).get()
        let url = URLS.ValidateTokenAPI + token;
        return this.http.get<any>(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            }));
        // return userDetails;
    }
      updateUserPassword(userData): Observable<any> {
    // return this.$http.baseUrlContext.url("/admin/api/user/public/save-password").put(userData)
    return this.http.put(URLS.UpdateUserPasswordAPI, userData, { headers: this.headers }).pipe(
      map((res: Response) => {
        return res;
        // return this.extractData(res);
      }));
  }

/*     getUserInfo(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.user !== undefined && this.user.info !== undefined) {
        resolve(this.user);
      } else {
        this.loadAuthFromStorage().then(() => {
          resolve(this.user);
        }, () => { reject(false) });
      }
    });
  } */
}
