
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OneAuthService } from 'one-auth';


@Injectable({
  providedIn: 'root',
})
export class PermissionRouteGuard implements CanActivate {
  applicationList: any[] = [];

  constructor(private auth: OneAuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (this.auth.user.info.id == undefined) {
      this.auth.LoadAuth().then(() => {
          this.applicationList = this.auth.user["permissions"];
          return this.checkPermissions(this.applicationList)
      });
    } else {
      this.applicationList = this.auth.user["permissions"];
      return this.checkPermissions(this.applicationList)
    }
    return true;
  }

  checkPermissions(resp) {
    // this.sideNav = this.carrierUserMenus;
    let connectApp = resp.CONNECT;
    if (connectApp) {
      if (connectApp.MODULES.ADMIN && connectApp.MODULES.ADMIN.actions && connectApp.MODULES.ADMIN.actions.view) {
        return true;
      } else if (connectApp.MODULES.DOC_MONITORING && connectApp.MODULES.DOC_MONITORING.actions.view) {
        this.router.navigate(['/document-monitoring']);
        return false;
      } else {
        this.router.navigate(['/my-preferences']);
        return false;
      }
    }
    this.router.navigate(['/my-preferences']);
    return false;
  }
}