import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment_ from 'moment';
import "moment-timezone";
const moment = moment_["default"];

@Component({
  selector: 'date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-us' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    //{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DatePickerComponent implements OnInit {

  @Input() parentForm: FormGroup;
  @Input() field: any;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() set disabledPicker(condition: boolean) {
    if (condition) {
      this.parentForm['controls'][this.field].disable();
    } else {
      this.parentForm['controls'][this.field].enable();
    }
  }
  @Input() disabledate: string;
  @Input() placeholder: string;
  @Input() removeTime: boolean;
  @Output() dateChanged: EventEmitter<any> = new EventEmitter<any>();
  @Input() rowValue: any = null;

  timezoneLocale: string;
  dateFormats: string[] = [
    'dd/MM/yyyy',
    'dd/MM/yyyy hh:mm:ss',
    'dd-MM-yyyy',
    'dd-MM-yyyy HH:mm:ss',
    'MM/dd/yyyy',
    'MM/dd/yyyy hh:mm:ss',
    'yyyy/MM/dd',
    'yyyy/MM/dd HH:mm:ss',
    'dd/MM/yy',
    'dd/MM/yy hh:mm:ss',
    'YYYY-MM-DDTHH:mm:ss.SSSZZ'
  ];
  constructor(private adapter: DateAdapter<any>, private fb: FormBuilder) { }

  dateChangeEvent(newdate) {
    if (newdate == null) {
      this.parentForm.get(this.field).setValue('');
      this.dateChanged.emit('Invalid date');
      return false;
    }
    //returns  date with current time of local machine
    let date = moment(newdate).add({ hours: moment().hour(), minutes: moment().minute(), seconds: moment().second() })
    let datePick: any;
    //Use this in case to send time as 00:00:00 considering UTC
    if (this.removeTime) {
      let isoDate = date.toISOString();
      let indexT = isoDate.indexOf('T');
      let substring = isoDate.substring(0, indexT);
      substring += "T00:00:00.000+0000";
      datePick = substring;
      this.dateChanged.emit(datePick);
    }
    else {
      datePick = moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]);
      //this.dateChanged.emit(moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]));
      this.dateChanged.emit(datePick);
    }

    if (this.rowValue) {
      datePick = moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]);
      if (datePick != 'Invalid date')
        this.rowValue.controls[this.field].setValue(moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]));
      else
        this.rowValue.controls[this.field].setValue('');

    } else {
      datePick = moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]);
      if (datePick != 'Invalid date')
        this.parentForm.get(this.field).setValue(moment(date).tz(this.timezoneLocale).format(this.dateFormats[10]));
      else
        this.parentForm.get(this.field).setValue('');

    }
  }

  ngOnInit() {
    this.getTimeZoneFromLocalstorage();
  }

  getTimeZoneFromLocalstorage() {
    //To do
    //add code to get time zone of user from local storage and assign to this.timezoneLocale variable
    this.timezoneLocale = 'Asia/Kolkata';
    this.adapter.setLocale('en-us');
  }

}
