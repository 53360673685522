import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { URLS } from 'src/environments/config.url';

@Injectable({
    providedIn: 'root'
})

export class CatalogService {

    private headers = new HttpHeaders();
    constructor(public http: HttpClient, @Inject('env') private env) {
        this.headers.set('Content-Type', 'application/json');
    }

    getProductList(pageNumber?, pageSize?, sortColumn?, sortOrder?): Observable<any> {
        let productlistRequest: any;
        productlistRequest = {
            "pageNumber": pageNumber,
            "pageSize": pageSize,
            "sortColumn": sortColumn,
            "sortOrder": sortOrder
        }
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetchCatalogItemList").post(productlistRequest)
        let url = this.env.BASE_API_URL + URLS.GetCatalogItemListAPI;
        return this.http.post(url, productlistRequest, { headers: this.headers }).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    saveProduct(product): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/addUpdate").put(product)
        let url = this.env.BASE_API_URL + URLS.AddUpdateCatalogAPI;
        return this.http.put(url, product, { headers: this.headers }).pipe(
            map((res: any) => {
                return res;
            })
        );
    }

    getProduct(productId): Observable<any> {
        // return this.$http.baseUrlContext.url("/catalog/api/catalog/fetchCatalogItemPrimary/" + productId).get()
        let url = this.env.BASE_API_URL + URLS.GetCatalogItemAPI + productId;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getDepartments(): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/topCategories').get()
        let url = this.env.BASE_API_URL + URLS.GetCatalogCategoriesAPI;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getCommodity(parentCategoryId): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/subCategories/' + parentCategoryId).get()
        let url = this.env.BASE_API_URL + URLS.GetCatalogSubCategories + parentCategoryId;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getCountryCodes(): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/origin').get()
        let url = this.env.BASE_API_URL + URLS.GetCatalogOrigin;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getUOM(): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/uom').get()
        let url = this.env.BASE_API_URL + URLS.GetUOM;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // this.extractData(res);
            })
        );
    }

    getGrowingMethods(): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/growingMethod').get()
        let url = this.env.BASE_API_URL + URLS.GetGrowingMethods;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    getPackTypes(): Observable<any> {
        // return this.$http.baseUrlContext.url('/catalog/api/catalog/packType').get()
        let url = this.env.BASE_API_URL + URLS.GetPackTypes;
        return this.http.get(url).pipe(
            map((res: Response) => {
                return res;
                // return this.extractData(res);
            })
        );
    }

    extractData(response) {
        return response.body;
    }

}
