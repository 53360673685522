import { environment } from './environment';

export const URLS = Object();

URLS.UserLoginServiceAPI = environment.BASE_API_URL + "login";
URLS.LogoutAPI = environment.BASE_API_URL + "auth/api/user/logout";
URLS.UserInfoServiceAPI = environment.BASE_API_URL + "auth/api/user/info";
URLS.UserPermissionAPI = environment.BASE_API_URL + "auth/api/user/user-app-module-permissions";
URLS.GetProfileImage = environment.BASE_API_URL + "admin/api/object-store/view/"
// URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";


URLS.GetAllOrgsDetailsAPI = environment.BASE_API_URL + "admin/getorgs"; // TBD

// Logistics Service
URLS.TradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/CARRIER/BUYER";
URLS.AssignedModelListAPI = environment.BASE_API_URL + "logistics/load/transmodes";
URLS.GetLoadListAPI = environment.BASE_API_URL + "logistics/load/carrier/list";
URLS.GetLoadPOSAPI = environment.BASE_API_URL + "logistics/load/carrier/fetchLoadPos/";

URLS.LoadFindAPI = environment.BASE_API_URL + "logistics/load/carrier/find/";
URLS.LoadCancel = environment.BASE_API_URL + "logistics/load/carrier/cancel";
URLS.RemoveOrderFromLoad = environment.BASE_API_URL + "logistics/load/remove/loadPOs/";

URLS.GetOrderCommentCount = environment.BASE_API_URL + "order/comment/count/PO";
URLS.GetOrderDetails = environment.BASE_API_URL + "order/po/short/";
URLS.SuggestLoadNumber = environment.BASE_API_URL + "logistics/load/carrier/search/suggest/";
URLS.SearchLoad = environment.BASE_API_URL + "logistics/load/carrier/search/";
URLS.GetOrgDetails = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetChargeSettings = environment.BASE_API_URL + "order/po/charge/setting/";
URLS.GetCharges = environment.BASE_API_URL + "admin/api/charge/list";
URLS.GetLoadChargeDetails = environment.BASE_API_URL + "logistics/load/carrier/charge/";

URLS.BUYER_APPLINK = environment.BASE_API_URL + "buyer-app/#/app/dashboard";
URLS.SUPPLIER_APPLINK = environment.BASE_API_URL + "vendor-app/#/app/dashboard";
URLS.CARRIER_APPLINK = environment.BASE_API_URL + "carrier-app/#/app/dashboard";
URLS.TURST_APPLINK = environment.BASE_API_URL + "trust-app/dashboard";
URLS.COMMON_APPLINK = environment.BASE_API_URL + "common-app/#/dashboard";
URLS.BUYER_APPLINK = environment.BASE_API_URL + "buyer-app/dashboard";
URLS.SUPPLIER_APPLINK = environment.BASE_API_URL + "supplier-app/dashboard";
URLS.CARRIER_APPLINK = environment.BASE_API_URL + "carrier-app/dashboard";
URLS.TURST_APPLINK = environment.BASE_API_URL + "trust-app/dashboard";

URLS.GetOrgBusinessTypeAPI = environment.BASE_API_URL + "admin/api/org/public/business-types";

URLS.CountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.StateListAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";

URLS.OrgNameExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-name/";
URLS.BusinessEmailExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-email/";
URLS.DunsNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/duns-exists-with-otherOrg/";
URLS.AddCompanyAPI = environment.BASE_API_URL + "admin/api/org/public/create";

//route details
URLS.GetRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/find-route/";
URLS.CreateRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/create-route-details";
URLS.UpdateRouteDetailsAPI = environment.BASE_API_URL + "logistics/load/update-route-details";

// Business Rules

URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
URLS.GetBusinessRulesAPI = environment.BASE_API_URL + "admin/api/access/fetch-businessRules/";
URLS.UpdatedBusinessRulesAPI = environment.BASE_API_URL + "admin/api/access/update-BusinessRules/";

// Business Unit

URLS.GetBusinessUnitTypeAPI = environment.BASE_API_URL + "admin/api/org/business-unit";
URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetStateByCountryCodeAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";
URLS.CreateBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/create";
URLS.GetBusinessUnityDetailsAPI = environment.BASE_API_URL + "admin/api/org/fetchOrgUnit/";
URLS.UpdateCompanyAPI = environment.BASE_API_URL + "admin/api/org/update-orgunit";
URLS.GetBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/fetchOrg-unit"
URLS.BusinessEmailExistAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-email/";
URLS.DUNSNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/duns-exists-with-otherOrg/";
URLS.ClientCodeExistsAPI = environment.BASE_API_URL + "admin/api/org/exists-by-unitcode/",


    URLS.getMonitoringXML = environment.BASE_API_URL + "edi/api/profile/fetch-document-XML-info/";
URLS.getMonitoringXMLBody = environment.BASE_API_URL + "edi/api/profile/fetch-document-XML-info";

// Catalog

URLS.getProductListAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemList"
URLS.AddUpdateProductAPI = environment.BASE_API_URL + "catalog/api/catalog/addUpdate";
URLS.GetProductAPI = environment.BASE_API_URL + "catalog/api/catalog/fetchCatalogItemPrimary/";
URLS.GetCatalogCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/topCategories";
URLS.GetCatalogSubCategoryAPI = environment.BASE_API_URL + "catalog/api/catalog/subCategories/";
URLS.GetCatalogCountryCodesAPI = environment.BASE_API_URL + "catalog/api/catalog/origin";
URLS.GetUOMAPI = environment.BASE_API_URL + "catalog/api/catalog/uom/";
URLS.GetGrowingMethodsAPI = environment.BASE_API_URL + "catalog/api/catalog/growingMethod";
URLS.DownloadFileAPI = environment.BASE_API_URL + "catalog/api/catalog/downloadTemplate";
URLS.GetPackTypesAPI = environment.BASE_API_URL + "catalog/api/catalog/packType";
URLS.GetProductDetailsAPI = environment.BASE_API_URL + "catalog/api/catalog/";
URLS.SuggestProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMyTradingPartnersAPI = environment.BASE_API_URL + "admin/api/trading-partners/buy-sell/my-trading-partners";
URLS.GetAllSubCategoriesAPI = environment.BASE_API_URL + "catalog/api/catalog/allSubCategories/";
URLS.GetPartnerCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerCatalogItems";
URLS.MapCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mapCatalogItem";
URLS.SearchProductAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest/";
URLS.GetMappedCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/mappedCatalogItems";
URLS.SearchOrderAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.CheckProductExistAPI = environment.BASE_API_URL + "catalog/api/catalog/check-product-code-exist/";
URLS.CheckGTINExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-if-gtin-exist";
URLS.CheckUPSCExistsAPI = environment.BASE_API_URL + "catalog/api/catalog/check-upc-exist/";
URLS.DownloadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/download-partners-catalog";
URLS.UploadPartnerCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/upload-partners-catalog";
URLS.SuggestCodeDescAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc/";
URLS.GetFileObject = environment.BASE_API_URL + "admin/api/object-store/";
URLS.UploadCatalogAPI = environment.BASE_API_URL + "catalog/api/catalog/uploadCatalog";
URLS.SearchProductCodeAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-code-desc-active/";
// ChargeList
URLS.GetChargeListAPI = environment.BASE_API_URL + "admin/api/charge/fetchChargeList";
URLS.GetChargeDetailsAPI = environment.BASE_API_URL + "admin/api/charge/fetchCharge/";
URLS.GetChargeCategoriesAPI = environment.BASE_API_URL + "admin/api/charge/categories";
URLS.GetChargeTypesAPI = environment.BASE_API_URL + "admin/api/charge/charge-types";
// URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
URLS.GetTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/applications/";
URLS.CreateChargeAPI = environment.BASE_API_URL + "admin/api/charge/create";
URLS.UpdateChargeAPI = environment.BASE_API_URL + "admin/api/charge/update";
URLS.SuggestChargeAPI = environment.BASE_API_URL + "admin/api/charge/search/suggest/";
URLS.SearchChargeAPI = environment.BASE_API_URL + "admin/api/charge/search/";
URLS.GetPaymentTermsListAPI = environment.BASE_API_URL + "admin/api/paymentterm/list/pageable";
URLS.GetPaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/fetchPaymentterm/";
URLS.CreatePaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/create";
URLS.UpdatePaymentTermAPI = environment.BASE_API_URL + "admin/api/paymentterm/update";
URLS.AddUpdateReasonAPI = environment.BASE_API_URL + "admin/api/claims/save/reason-codes";
URLS.GetUpdatedReasonAPI = environment.BASE_API_URL + "admin/api/claims/get/reason-codes";
URLS.GetCharges = environment.BASE_API_URL + "admin/api/charge/list";

URLS.GetChargeSettings = environment.BASE_API_URL + "order/po/charge/setting/";

// Company
URLS.GetBusinessTypeAPI = environment.BASE_API_URL + 'admin/api/org/public/business-types';
// URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.GetStateByCountryCodeAPI = environment.BASE_API_URL + "admin/api/address/public/state-list/";
URLS.OrgNameExistsAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-name/";
URLS.OrgNameExistsAtEditAPI = environment.BASE_API_URL + "admin/api/org/exists-by-name/";
URLS.BizUnitNameExistsAPI = environment.BASE_API_URL + "admin/api/org/bizunit-exists-by-name/";
// URLS.BusinessEmailExistAPI = environment.BASE_API_URL + "admin/api/org/public/exists-by-email/";
// URLS.DUNSNumberExistsAPI = environment.BASE_API_URL + "admin/api/org/duns-exists-with-otherOrg/";
URLS.AddCompanyAPI = environment.BASE_API_URL + "admin/api/org/public/create";
URLS.GetCompanyDetailsAPI = environment.BASE_API_URL + "admin/api/org/fetchOrg";
URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetDateFormatAPI = environment.BASE_API_URL + "admin/api/org/available-dateFormats";
URLS.UpdateCompanyDetailsAPI = environment.BASE_API_URL + "admin/api/org/update";
URLS.GetAllModulesAPI = environment.BASE_API_URL + "admin/api/access/fetch-ApplicationModules";
URLS.UpdateModuleAPI = environment.BASE_API_URL + "admin/api/access/update-ApplicationModules";
URLS.GetDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attribute-list";
URLS.GetDepartmentAPI = environment.BASE_API_URL + "admin/api/user/attribute-details/";
URLS.UpdateDepartmentAPI = environment.BASE_API_URL + "admin/api/user/update-attribute";
URLS.AddDepartmentAPI = environment.BASE_API_URL + "admin/api/user/create-attribute";
URLS.DepartmentExistsAPI = environment.BASE_API_URL + "admin/api/user/exists-by-attribute-name/";

URLS.GetDocumentListAPI = environment.BASE_API_URL + "admin/api/document/all";
URLS.DeteltDocumentAPI = environment.BASE_API_URL + "admin/api/document/delete";
URLS.GetDocumentTypeAPI = environment.BASE_API_URL + "admin/api/document/document-types";
URLS.GetDocThumbnailList = environment.BASE_API_URL + "admin/api/document/all/certificates-licenses";

URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";
URLS.GetFileObjectViewAPI = environment.BASE_API_URL + "admin/api/object-store/view/";
URLS.GetFileObjectDocument = environment.BASE_API_URL + "admin/api/object-store/download/";
URLS.GetCommodityListAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity-list";
URLS.GetCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity/";
URLS.UpdateCommodityAPI = environment.BASE_API_URL + "catalog/api/catalog/update-commodity";
URLS.SearchCommoditybyNameAPI = environment.BASE_API_URL + "catalog/api/catalog/search/suggest-commodity/";
URLS.SearchOrderAPI = environment.BASE_API_URL + "order/buy/search/";
URLS.GetUserDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";
URLS.GetCommodityByDepartment = environment.BASE_API_URL + "catalog/api/catalog/fetch-commodity-list-on-category-selection";
URLS.GetCommodityOnKey = environment.BASE_API_URL + "catalog/api/catalog/search/commodity/";
URLS.UpdateSelectedCommodity = environment.BASE_API_URL + "catalog/api/catalog/update-selected-commodities";
URLS.UploadDocument = environment.BASE_API_URL + "admin/api/document/upload";
URLS.UserGetDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";


URLS.GetRangeListByAPPAPI = environment.BASE_API_URL + "admin/api/range/list";
URLS.GetRangeAPI = environment.BASE_API_URL + "admin/api/user/attribute-details/";
URLS.UpdateRangeAPI = environment.BASE_API_URL + "admin/api/range/addUpdate";
URLS.GetRangeTypeListAPI = environment.BASE_API_URL + "admin/api/range/types/";
URLS.GetApplyByAPI = environment.BASE_API_URL + "admin/api/range/apply-by";
URLS.GetBusinessUnitListOnAppCodeAPI = environment.BASE_API_URL + "admin/api/org/business-units/";
URLS.isPrefixExistAPI = environment.BASE_API_URL + "admin/api/range/is-prefix-duplicate/";


// Core

URLS.ValidateTokenAPI = environment.BASE_API_URL + "admin/api/user/public/validate-token/";
URLS.UpdateUserPasswordAPI = environment.BASE_API_URL + "admin/api/user/public/save-password";

// IOT

URLS.IOTDataListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTDataListChildAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetDetailsAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTCreateAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTUpdateAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTSuggestAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetStatusListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetCategoryListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetTypeListAPI = environment.BASE_API_URL + "iot/api/";
URLS.IOTGetParameterLIstAPI = environment.BASE_API_URL + "iot/api/node/parameter/";
URLS.IOTGetConfigurationListAPI = environment.BASE_API_URL + "iot/api/configuration/list";


// Trading Partner

// URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
// URLS.GetTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/applications/";
URLS.GetMyTradingPartnersListAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners";
URLS.GetAvailableTradingPartnersAPI = environment.BASE_API_URL + "admin/api/trading-partners/all";
URLS.AddSelectedTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/add";
URLS.RemoveTradingPartnerByIdAPI = environment.BASE_API_URL + "admin/api/trading-partners/remove";
// URLS.GetPartnerCatalogItemsAPI = environment.BASE_API_URL + "catalog/api/catalog/partnerCatalogItems";
URLS.GetPartnerListByTypeAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners";
URLS.GetTradingPartnerCodeListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get/codes";
URLS.SuggestTradingPartnerByCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/search-suggest/codes/";
URLS.AddUpdatePartnerCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/add-update/codes";
URLS.DeleteTradingPartnerCodeAPI = environment.BASE_API_URL + "admin/api/trading-partners/delete/codes ";
URLS.GetAllTradingPartnerCodeListAPI = environment.BASE_API_URL + "admin/api/trading-partners/search/codes/";
URLS.SuggestAvailableTradingPartnerAPI = environment.BASE_API_URL + "admin/api/trading-partners/search/suggest-trading-partners";

// User

URLS.GetUserList = environment.BASE_API_URL + "admin/api/user/fetchUserList";
URLS.GetUser = environment.BASE_API_URL + "admin/api/user/fetchUser/";
URLS.GetUserProfile = environment.BASE_API_URL + "admin/api/user/me";
// URLS.GetDepartmentListAPI = environment.BASE_API_URL + "admin/api/user/attributes/DEPARTMENT";
URLS.GetUserTypes = environment.BASE_API_URL + "admin/api/user/user-types";
// URLS.GetCountryListAPI = environment.BASE_API_URL + "admin/api/address/public/countries";
URLS.CreateUser = environment.BASE_API_URL + "admin/api/user/create";
URLS.UpdateUser = environment.BASE_API_URL + "admin/api/user/update";
URLS.ForgotPassword = environment.BASE_API_URL + "admin/api/user/public/forgot-password";
// URLS.GetSubscribedApplicationsAPI = environment.BASE_API_URL + "admin/api/access/fetch-SubscribedApplications";
URLS.GetModuleAndActions = environment.BASE_API_URL + "admin/api/access/fetch-ModulesAndActions/";
URLS.GetUserPermissions = environment.BASE_API_URL + "admin/api/user/fetch-userPermissions";
URLS.SaveUserPermissions = environment.BASE_API_URL + "admin/api/user/create-edit-permissions";
URLS.UserCodeExistsAPI = environment.BASE_API_URL + "admin/api/user/is-usercode-exists/";

URLS.GetLanguagesAPI = environment.BASE_API_URL + "admin/api/org/languages";
URLS.GetCurrencyAPI = environment.BASE_API_URL + "admin/api/org/currencies";
URLS.GetTimeZonesAPI = environment.BASE_API_URL + "admin/api/org/available-timezones";
URLS.GetProfileOverviewAPI = environment.BASE_API_URL + "admin/api/org/overview/";
// Services for edi Component
URLS.GetDocumentType = environment.BASE_API_URL + "edi/api/profile/doc-type";
URLS.GetTriggerEvents = environment.BASE_API_URL + "edi/api/profile/trigger-events/";
URLS.GetOrganizationsByAppCode = environment.BASE_API_URL + "edi/api/profile/getOrganizationsBy-appCode/";
URLS.GetBussinessRules = environment.BASE_API_URL + "edi/api/profile/bussiness-rules/";
URLS.SetupTradingPartner = environment.BASE_API_URL + "edi/api/profile/create-integration-partner";
URLS.FetchPartnerProfileList = environment.BASE_API_URL + "edi/api/profile/fetch-partner-profile-list";
URLS.GetCustomer = environment.BASE_API_URL + "edi/api/profile/getOrgCustomerList";
URLS.GetDocumentTypeById = environment.BASE_API_URL + "edi/api/profile/fetch-document-type-by-partner/";
URLS.GetDocumentStatus = environment.BASE_API_URL + "edi/api/profile/fetch-document-status";
URLS.GetDocumentList = environment.BASE_API_URL + "edi/api/profile/fetch-document-header-info";
URLS.GetDocumentListN = environment.BASE_API_URL + "edi/api/profile/fetch-document-header-info-dm";
URLS.GetDocumentStatusN = environment.BASE_API_URL + "edi/api/profile/fetch-document-status-dm";
URLS.GetDocumentdetails = environment.BASE_API_URL + "edi/api/profile/fetch-document-details-info";
URLS.GetDocumentdetailsIFT = environment.BASE_API_URL + "edi/api/profile/fetch-document-info";
URLS.ReprocessDocument = environment.BASE_API_URL + "edi/api/profile/update";
URLS.DownloadEDIFileAPI = environment.BASE_API_URL + "edi/api/profile/download-document-csvFormat";
URLS.ReprocessAllDocument = environment.BASE_API_URL + "edi/api/profile/allReprocess";

// Internal Admin
URLS.GetRegisteredOrgListAPI = environment.BASE_API_URL + "admin/api/internal-admin/fetch-all-registeredorgs";
URLS.ActiveOrInactiveRegOrgAPI = environment.BASE_API_URL + "admin/api/internal-admin/update-orgstatus";
URLS.GetLastUpdatedUserInfoAPI = environment.BASE_API_URL + "admin/api/internal-admin/recent-update-info/";
URLS.SearchSuggestForOrgsAPI = environment.BASE_API_URL + "admin/api/internal-admin/org/search-suggest/";
URLS.GetOrgListOnSearchAPI = environment.BASE_API_URL + "admin/api/internal-admin/org/search-by-name-or-id";

//Auto Charges
//URLS.GetSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/my-trading-partners/BUYER/SUPPLIER";
URLS.GetSupplierListAPI = environment.BASE_API_URL + "admin/api/trading-partners/get-online-offline-suppliers/BUYER/SUPPLIER";
URLS.ShipsToBusinessUnitAPI = environment.BASE_API_URL + "admin/api/org/businessUnits/DELIVERY";
URLS.GetSupplierCodesOfSupplier = environment.BASE_API_URL + "admin/api/org/buyer/supplier-codes/";
URLS.GetAutoChargeListAPI = environment.BASE_API_URL + "admin/api/charge/fetch-auto-charge-list";
URLS.GetAutoChargeDetailsAPI = environment.BASE_API_URL + "admin/api/charge/fetch-auto-charge/";
URLS.UpdateAutoChargeAPI = environment.BASE_API_URL + "admin/api/charge/auto-charge-update";
URLS.CreateAutoChargeAPI = environment.BASE_API_URL + "admin/api/charge/auto-charge-create";
URLS.GetAutoProrateByAPI = environment.BASE_API_URL + "admin/api/charge/charge-prorate-by";
URLS.GetAutoApplyByAPI = environment.BASE_API_URL + "admin/api/charge/charge-apply-by";

//My preferences
URLS.GetFetchNotifications = environment.BASE_API_URL + "admin/api/user/preferences/notifications/fetch/";
URLS.SaveEmailNotification = environment.BASE_API_URL + "admin/api/user/preferences/notifications/save-preferences";

URLS.UpdateUserProfile = environment.BASE_API_URL + "admin/api/user/updateUserProfile";
URLS.ChangeUserProfilePasswordAPI = environment.BASE_API_URL + "admin/api/user/change-password";