import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }
  @Input() parentForm: FormGroup;
  @Input() field: any;
  @Input() listData: any;
  @Input() disabled: boolean = false;
  @Input() isShowAllOption: boolean = true;
  @Input() valuetoShow: string;
  @Input() valuetoBind: any;
  @Input() placeholder: string;
  @Input('isDirty') isDirty: boolean;
  @Output() onListChanged: EventEmitter<any> = new EventEmitter<any>();
  selectedList = [];
  @Input() allSelected: boolean;
  @Input() requiredError: string;
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  toggleSelect(event) {
    this.selectedList = [];
    if (event.value) {
      event.value.forEach(element => {
        this.selectedList.push(element);
      });
    }
    if (this.selectedList.length === this.listData.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.onListChanged.emit(this.selectedList);
  }

  masterToggle(event) {
    this.selectedList = [];
    if (event.checked) {
      this.listData.forEach(element => {
        if (this.valuetoBind) {
          this.selectedList.push(element[this.valuetoBind]);
          this.parentForm.controls[this.field].patchValue([...this.listData.map(item => item[this.valuetoBind])]);
        } else {
          this.selectedList.push(element);
          this.parentForm.controls[this.field].patchValue([...this.listData.map(item => item)]);
        }
      });
    } else {
      this.selectedList = [];
      this.parentForm.controls[this.field].patchValue([]);
    }
    if (this.selectedList.length === this.listData.length) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
    this.onListChanged.emit(this.selectedList);
  }
  /**
* // TODO: claim-header ngAfterContentChecked.
* Claims header
* @description Check parant form dirty or not(life cycle method).
* @author Ashish Patil
*/
  ngAfterContentChecked(): void {
    if (this.isDirty) {
      this.parentForm.controls[this.field].markAsTouched()
    }
  }
}
