<div class="app-container">
  <header-toolbar class="top-toolbar" *ngIf="!isLogin || isAppSelector"></header-toolbar>
  <mat-sidenav-container fullscreen [ngClass]="(isLogin)?'noheader':''">
    <!-- <app-header></app-header> -->
    <mat-sidenav [disableClose]="true" #sidenav mode="side" opened *ngIf="!isLogin">
      <side-toolbar class="sidenav-toolbar"></side-toolbar>
    </mat-sidenav>
    <mat-sidenav-content role="main">
      <div class="content-container">
        <!-- <content-infobar></content-infobar> -->
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
<ng-template #LoginPage>
  <div class="login-container">
    <router-outlet></router-outlet>
  </div>
</ng-template>