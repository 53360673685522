import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OneAuthService } from 'one-auth';
import { InitLoaderService } from './services/initloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  private page: string;
  //public isAuthenticated: boolean = false;

  public isLogin: boolean = false;
  public isAppSelector = false;

  constructor(private init: InitLoaderService, private auth: OneAuthService, public translate: TranslateService, public router: ActivatedRoute, public route: Router, private location: Location) {
    this.translate.setDefaultLang('en_US');
    this.init.load();
  }

  ngOnInit() {

    if (this.auth.user.info.id === undefined) {
      /*       console.log("no user data");
            this.auth.LoadAuth().then(()=>{
              
            }); */
    }

    this.route.events.subscribe((pat) => {
      // let i = this.auth.user.subApps.filter(t => t.applicationCode == "BUYER")
      // console.log(i);
      // if (i) {
      if (this.route.url === "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.route.url === "/logout") {
        if (!this.isLogin) {
          this.isLogin = true;
        }
      } else if (this.route.url !== "/BBF65054CC9C2C8CB6692B2A1648FD8C" && this.route.url !== "/logout") {

        if (this.isLogin) {
          this.isLogin = false;
        }
      }
      // } else {
      //   this.route.navigate(['apps']);
      // }
    });
  }

  // ngAfterViewInit(){
  //   if(this.location.path() == "/BBF65054CC9C2C8CB6692B2A1648FD8C" || this.location.path() == "/logout"){
  //     console.log('settig login view to true')
  //     this.isLogin = true;
  //   } else{
  //     console.log('settig login view to false')
  //     this.isLogin = false;
  //   }
  // }

  // This is temporary till the App Based Login is done
  doTemporaryLogin() {
    /*  //this.appStorage.loadMeta();
     let u = "retailer@procurant.com";
     let p = "password";
     // let u = "roryfields@acme.com";
     //         let p = "Password19!";
     // let u = "buyer-1@procurant.com";
     // let p = "%Password19";
     this.auth.validate(u, p).subscribe((res) => {
       this.auth.getUserInfoPermission().subscribe((res) => {
         //this.appStorage.loadMeta();
       })
     }) */
  }

}
