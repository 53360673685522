import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as moment_ from 'moment';
const moment = moment_["default"];

@Injectable({
  providedIn: 'root'
})
export class CommonServices {

  constructor(private snackBar: MatSnackBar) { }

  // public static namesPattern = '^[a-zA-Z0-9!@#$*%^&?_+=~`|:;"<>,.-]+( [a-zA-Z0-9!@#$*%^&?_+=~`|:;"<>,.-]+)*$';
  public static aplhaNumericHyphen = "^[^\\s!@#$%^&*()\\._+=\\:;\"\\',<>\\/?]+(\\s+[^\\s!@#$%^&*()\\._+=\\:;\"\\',<>\\/?]+)*$";
  public static aplhaNumeric = "^[^\\s!@#$%^&*()\-\\._+=\\:;\"\\',<>\\/?]+(\\s+[^\\s!@#$%^&*()\-\\._+=\\:;\"\\',<>\\/?]+)*$";

  //public static namesPattern = '^[^\\s]+(\\s+[^\\s]+)*$';
  public static namesPattern = "^[a-zA-Z0-9\u4e00-\u9eff][a-zA-Z\u4e00-\u9eff\\s0-9!@#$%^&*()\\-_+=\\:;\"\\',<>\\/?]+$";

  // public static fnamePattern = "^[a-zA-Z0-9][a-zA-Z0-9 .]*$";
  public static fnamePattern = "^[^\\s0-9!@#$%^&*()\\-_+=\\:;\"\\',<>\\/?]+(\\s+[^\\s0-9!@#$%^&*()\\-_+=\\:;\"\\',<>\\/?]+)*$";

  // public static lnamePattern = "^[a-zA-Z0-9][a-zA-Z0-9]*$";
  public static lnamePattern = "^[^\\s0-9!@#$%^&*()\\-_+=\\:;\"\\',<>\\/?]+(\\s+[^\\s0-9!@#$%^&*()\\-_+=\\:;\"\\',<>\\/?]+)*$";
  public static emailPatternold = '^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';
  public static emailPattern = '^[\\w]{1,}[\\w.+-]{0,}@[\\w-]{1,}([.][a-zA-Z]{2,15}|[.][a-zA-Z]{2,15}[.][a-zA-Z]{2,15})$';
  // public static phonePattern = '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]{8}[0-9]+$';
  public static phonePattern = '^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$';
  // public static addressLinePattern = '^[a-zA-Z0-9!@#$*%^&?_+=~`|:;"<>,.-]+( [a-zA-Z0-9!@#$*%^&?_+=~`|:;"<>,.-]+)*$';
  public static addressLinePattern = '^[^\\s]+(\\s+[^\\s]+)*$';

  // public static urlPattern = '^((https?|ftp|smtp):\\/\\/)?(www.)?[a-z0-9]+(\\.[a-z]{2,}){1,3}(#?\\/?[a-zA-Z0-9#]+)*\\/?(\\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$';
  public static urlPattern = '^[^\\s]+(\\s+[^\\s]+)*$';
  public static passwordPattern = '(?=(.*[0-9]))(?=.*[!@#$*%^&?_+=~`|:;"<>,.\\-])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}';
  //public static zipCodePattern = '^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$';
  public static zipCodePattern = '^[a-zA-Z0-9\u4e00-\u9eff]+(-[a-zA-Z0-9\u4e00-\u9eff]+)*$';

  public static dunsPattern = '^[0-9][0-9-]{7}[0-9]+$';
  //public static clientCodePattern = '^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$';
  public static clientCodePattern = '^[a-zA-Z0-9\u4e00-\u9eff]+(-[a-zA-Z0-9\u4e00-\u9eff]+)*$';
  //public static descPattern = "^[^\\s!@#$%^&*()\\-_+=\\:;\"\',<>\\/?][a-zA-Z0-9\\s!@#$%^&*()\\-_+=\\:;\"\',<>\\/?]+$";
  public static descPattern = "^[^\\s!@#$%^&*()\\-_+=\\:;\"\',<>\\/?][a-zA-Z0-9\u4e00-\u9eff\\s!@#$%^&*()\\-_+=\\:;\"\',<>\\/?]+$";
  public static emo_regex = "([\\u20a0-\\u32ff\\ud83c\\udc00-\\ud83d\\udeff\\udbb9\\udce5-\\udbb9\\udcee])";

  public static alphaNumericWith_Hyphen = '^[a-zA-Z]+[a-zA-Z0-9-_ ]*[a-zA-Z0-9]$';
  public static alphaNumericWithAnd_HyphenDot = '^[a-zA-Z]+[a-zA-Z0-9-_&. ]*[a-zA-Z0-9]$';
  public static alphaNumericWith_HyphenNoSpace = '^[a-zA-Z0-9]+[a-zA-Z0-9-_]*[a-zA-Z0-9]$';

  public static phoneValidator(number): any {
    var PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/;
    var valid = PHONE_REGEXP.test(number.value);
    return valid ? null : { invalidNumber: true };
  }

  public static alphaNumericValidator(text): any {//Without space
    //var ALPA_NUMERIC = /^[A-Za-z0-9-]+$/;
    var ALPA_NUMERIC = /^[a-zA-Z0-9\u4e00-\u9eff]+(-[a-zA-Z0-9\u4e00-\u9eff]+)*$/;
    var valid = ALPA_NUMERIC.test(text.value);
    return valid ? null : { invalidAplphaNumeric: true };
  }

  public static negativeNumberValidator(number): any {
    var NEGATIVE_NUMBER = /^(?!^0*$)(?!^0*\.0*$)^\d{1,9}(\.\d{1,9})?$/;
    var valid = NEGATIVE_NUMBER.test(number.value);
    return valid ? null : { invalidNumber: true };
  }

  public static decimalNumberValidator(number): any {
    var DECIMAL_NUMBER = /^[0-9]+(\.^|[^,][0-9]{1,4})?$/;
    var valid = DECIMAL_NUMBER.test(number.value);
    return valid ? null : { invalidDecimalNumber: true };
  }

  public static priceDecimalValidator(number): any {
    var PRICE_DECIMAL_NUMBER = /(^[0-9]{0,4})+(\.^|[^,-][0-9]{1,4})?$/;
    var valid = PRICE_DECIMAL_NUMBER.test(number.value);
    return valid ? null : { invalidPriceDecimalNumber: true };
  }

  public storedDate: any;
  public dateRangeObj: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  showSnackBarMessage(message: string, time?: number) {
    this.snackBar.open(message, '', {
      duration: time || 4000, horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  convertToDate(date: Date) {
    var d = new Date(date);
    var curr_date = d.getDate();
    var curr_month = d.getMonth();
    curr_month++;
    var curr_year = d.getFullYear();
    return new Date(curr_month + "/" + curr_date + "/" + curr_year + ' 00:00:00 AM')
  }

  handleError(error) {
    let errorMessage = '';
    if (error.headers) {
      if (error.headers.get('Error')) {
        errorMessage = error.headers.get('Error')
      }
      else if (error.error) {
        errorMessage = error.error
      }
    }
    return errorMessage;
  }

  rememberDateSession(key: string) {
    /* this.localStorage.setItem(key, JSON.stringify(this.dateRangeObj)).subscribe((res) => {

    }); */
    this.setSessionData(key, JSON.stringify(this.dateRangeObj));
  }

  setDateSession(key: string, previousMonth: number = 1, nextMonth: number = 1): any {
    /* this.localStorage.getItem(key).subscribe((dte: any) => {
      if(dte != undefined && dte != null){
        this.storedDate = JSON.parse(dte);
      }
    });
    if (this.storedDate) {
      this.dateRangeObj = {
        fromDate: this.storedDate.fromDate,
        toDate: this.storedDate.toDate
      }
    } */
    let item = this.getSessionData(key);
    if (item) {
      this.dateRangeObj = {
        fromDate: item.fromDate,
        toDate: item.toDate
      }
    }
    else {
      let fromDate = new Date();
      let toDate = new Date();
      //get Previous month from current date
      fromDate.setMonth(fromDate.getMonth() - previousMonth);

      //get next month from current date
      toDate.setMonth(toDate.getMonth() + nextMonth);

      //Get First date of previous month
      fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
      //Get Last date of previous month
      toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);

      //Convert to UTC format
      let from = this.convertToDate(fromDate);
      let to = this.convertToDate(toDate);

      //assign to return object
      this.dateRangeObj = {
        fromDate: from,
        toDate: to
      }
    }
    return this.dateRangeObj;
  }

  getCurrancySymbol() {
    const currency: any = window.sessionStorage['currency'];
    switch (currency) {
      case 'CNY':
        return '¥';
      case 'USD':
        return '$';
      case 'EUR':
        return '€';
      default:
        return '$';
    }
  }

  getCurrancy() {
    const currency: any = window.sessionStorage['currency'];
    switch (currency) {
      case 'CNY':
        return 'CNY';
      case 'USD':
        return 'USD';
      case 'EUR':
        return 'EUR';
      default:
        return 'USD';
    }
  }

  public dateWithoutTime(date: any): any {
    let datePick: any;
    date = new Date(date);
    let isoDate = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    let indexT = isoDate.indexOf('T');
    let substring = isoDate.substring(0, indexT);
    substring += "T00:00:00.000+0000";
    datePick = substring;
    return moment(datePick).hours(0).minutes(0).seconds(0).milliseconds(0).format('YYYY-MM-DDTHH:mm:ss');
  }

  //added code for Get name from Status Enum and viceversa
  getStatus(statusName: string): string {
    statusName = statusName.replace(" ", "_");
    return PO_STATUS[statusName];
  }

  //added code for Get exact name from Status Enum
  getStatusName(statusName: string): string {
    statusName = statusName.replace(" ", "_");
    return statusName;
  }

  /* Temporary date remember session level storage */

  setSessionData(key: string, data: any) {
    window.sessionStorage.setItem(key, data);
  }

  getSessionData(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  removeSessionData() {
    window.sessionStorage.removeItem('loadList');
  }

}

export enum PO_STATUS {
  New = 1,
  Modified,
  Acknowledged,
  Shipped,
  Shipped_Modified,
  Received,
  Cancelled
}