<form [formGroup]="productForm" autocomplete="off">
  <div fxLayout="column" fxLayout.xs="column" fxFlexFill fxLayoutAlign="start none" class="mb-40">
    <div fxLayout="row" fxFlex="100">
      <div fxLayout="column" fxFlex="80">
        <div fxFlex="100">
          <div class="formContainer halfFields">
            <mat-form-field>
              <input readonly matInput formControlName="code" placeholder="{{ 'CATALOG.COLUMN.PRODUCT_CODE' | translate }}" maxlength="32">

            </mat-form-field>
            <mat-form-field>
              <input readonly matInput formControlName="description" placeholder="{{ 'CATALOG.COLUMN.DESCRIPTION' | translate }}" maxlength="256">

            </mat-form-field>
            <mat-form-field>
              <input readonly matInput formControlName="gtin" digitOnly placeholder="{{ 'CATALOG.COLUMN.GTIN' | translate }}" maxlength="14">

            </mat-form-field>
            <mat-form-field>
              <input readonly matInput formControlName="upc" digitOnly placeholder="{{ 'CATALOG.COLUMN.UPC' | translate }}" maxlength="12">


            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20" fxLayoutAlign=" end">
          <img [src]="localUrl" *ngIf="localUrl">
        <button disabled type="button" *ngIf="!localUrl" mat-raised-button class="btn btnSecondary catalogImgUploadBtn">{{ 'BUTTON.NO_IMAGE' | translate }}</button>
      </div>
    </div>
    <div fxFlex="100" class="mt-20">
      <div fxLayout="column" fxFlex="100">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="filterLinkBtn" value="primary">
          <mat-button-toggle value="primary">{{'CATALOG.LABEL.PRIMARY' | translate}}</mat-button-toggle>
          <mat-button-toggle value="packing">{{'CATALOG.LABEL.PACKING_SHIPPING'
            | translate}}</mat-button-toggle>
          <mat-button-toggle value="nutritional">{{'CATALOG.LABEL.NUTRITIONAL' | translate}}</mat-button-toggle>
          <mat-button-toggle value="additional">{{'CATALOG.LABEL.ADDITIONAL' |
            translate}}</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div fxFlex="100" class="mt-20">
      <div class="responsiveTable">
        <table mat-table [dataSource]="dataSource" matSort class="table">

          <ng-container matColumnDef="Sr">
            <th mat-header-cell *matHeaderCellDef width='1%' class="textCenter"> {{ 'CATALOG.COLUMN.SR' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="textCenter"> {{element.Sr}} </td>
          </ng-container>

          <ng-container matColumnDef="Attributes">
            <th mat-sort-header mat-header-cell *matHeaderCellDef width='40%'> {{ 'CATALOG.COLUMN.ATTRIBUTES' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{element.Attributes | translate}} <span *ngIf="requiredFields.includes(element.value.field)">*</span></td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-sort-header mat-header-cell *matHeaderCellDef width='69%'> {{ 'CATALOG.COLUMN.VALUE' | translate }} </th>
            <td mat-cell *matCellDef="let element;let i = index">

              <mat-form-field *ngIf="element.value.component=='radio'">
                <mat-radio-group [formControlName]="element.value.uomfield">
                  <input [readonly]="element.value.disabled" style="width: 90px;" matInput [formControlName]="element.value.field" maxlength="8" fourdecimalPlace>
                  <mat-radio-button [disabled]="element.value.disabled" value="KG" (change)="radioChange($event.value)">{{'CATALOG.LABEL.KG' | translate}}</mat-radio-button>
                  <mat-radio-button [disabled]="element.value.disabled" value="LB" (change)="radioChange($event.value)">{{'CATALOG.LABEL.LB' | translate}}</mat-radio-button>
                </mat-radio-group>
              </mat-form-field>

              <mat-form-field *ngIf="element.value.component=='dropdwon'">
                <mat-select [disabled]="element.value.disabled" *ngIf="!productForm.controls[element.value.field].validator" placeholder="" [formControlName]="element.value.field" disableOptionCentering>
                  <mat-option *ngFor="let opt of getDropDownOption(element.value.field)" [value]="opt.code">{{opt.name}}</mat-option>
                </mat-select>
                <!-- <mat-select disabled *ngIf="productForm.controls[element.value.field].validator" placeholder="" [formControlName]="element.value.field" required (selectionChange)="setValue($event,element.value.field)" disableOptionCentering>
                  <mat-option *ngFor="let opt of getDropDownOption(element.value.field)" [value]="opt.name">{{opt.name}}</mat-option>
                </mat-select> -->
              </mat-form-field>

              <mat-form-field *ngIf="element.value.component=='input'">
                <input [readonly]="element.value.disabled" matInput [formControlName]="element.value.field" maxlength="10" fourdecimalPlace>
              </mat-form-field>

              <mat-form-field *ngIf="element.value.component=='label'">
                <input [readonly]="element.value.disabled" matInput [formControlName]="element.value.field">
              </mat-form-field>

              <mat-error *ngIf="productForm.controls[element.value.field].touched && isGrossNetWeigtValid() && element.value.field==='grossWeight'">
                {{ 'MESSAGE.ERROR_GROSS_WEIGHT_LESS_THAN_NET' | translate }}
              </mat-error>
              <mat-error *ngIf="productForm.controls[element.value.field].touched && !productForm.controls[element.value.field].hasError('required') && productForm.controls[element.value.field].hasError('invalidNumber') && !productForm.controls[element.value.field].hasError('invalidDecimalNumber')">
                {{ 'MESSAGE.ERROR_NEGATIVE_ZERO_NUMBERS_NOT_ALLOWED' | translate }}
              </mat-error>
              <mat-error *ngIf="productForm.controls[element.value.field].touched && productForm.controls[element.value.field].hasError('required')">
                {{ 'MESSAGE.ERROR_REQ_FIELD' | translate }}
              </mat-error>
              <mat-error *ngIf="productForm.controls[element.value.field].touched && productForm.controls[element.value.field].hasError('invalidDecimalNumber') && !productForm.controls[element.value.field].hasError('required')">
                {{ 'MESSAGE.ERROR_FOUR_DECIMAL' | translate }}
              </mat-error>
              <mat-error *ngIf="element.value.field==='basePrice' && productForm.controls[element.value.field].hasError('max')">
                {{ 'MESSAGE.PRICE_BETWEEN_ERROR' | translate }}
              </mat-error>
              <mat-error *ngIf="productForm.controls[element.value.field].hasError('max') && !productForm.controls[element.value.field].hasError('required')">
                  {{ 'MESSAGE.WEIGHT_BETWEEN_ERROR' | translate }}
              </mat-error>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;">
          </tr>
        </table>
      </div>
    </div>
  </div>
</form>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" *ngIf="showButtons">
  <button mat-button class="btn btnBasic" (click)="onNoClick()">{{ 'BUTTON.CANCEL' | translate }}</button>
  <button mat-button class="btn btnPrimary" [disabled]="productForm.invalid || status || isGrossNetWeigtValid()" (click)="submitLineChange()">
    <span>{{ 'BUTTON.UPDATE' | translate }}</span>
  </button>
</div>