
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { BehaviorSubject } from "rxjs";
import { CatalogService } from "../../services/catalog.service";
import { CommonServices } from '../../services/common-services.service';
// import { ValidatorsService } from "../service/validators.service";
export interface DialogData {
  title: string;
}
export class DropDownOptions {
  type: String;
  attributes: any = [{
    name: String,
    code: String
  }];
};

export const DropDownNames = Object.freeze({
  DEPARTMENT: 'department',
  COMMODITY: 'commodity',
  PACKT_TYPE: 'packType',
  PRICE_PER: 'priceByUom',
  GROWING_METHOD: 'growingMethod',
  COO: 'coo',
  UOM: 'uom',
  STATUS: 'status'
});

@Component({
  selector: 'product-overlay',
  templateUrl: './product-overlay.component.html',
  styleUrls: ['./product-overlay.component.css']
})
export class ProductOverlayComponent implements OnInit {
  columns = [{ title: 'check', field: 'select' }, { title: 'Sr', field: 'sr' }, { title: 'User', field: 'user' }];
  columnsToDisplay = [];
  productForm: FormGroup;
  @Input() parentForm: FormGroup;
  @Input() status: boolean = false;
  attributeCategory = [];
  optionsObj = [];
  dropdown = [];
  modalData: any;
  uom = 'Kg';
  isSave: boolean = false;
  isEdit: boolean = false;
  displayedColumns: string[] = ['Sr', 'Attributes', 'value'];
  catalogItem: any;
  productId: number;
  @Input() catalogAttributes: any = [];
  @Input() showButtons?: boolean = true;

  //Set the DataTable Source from interface definition
  dataSource = new BehaviorSubject<any>([]);
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public dialogRef: MatDialogRef<ProductOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private fb: FormBuilder, private cdr: ChangeDetectorRef, private catalog: CatalogService) {
    this.productForm = fb.group({
      id: [],
      code: [''],
      description: [''],
      gtin: [''],
      upc: [''],
      weightUom: ['KG'],
      netWeightUom: ['KG'],
      organizationId: [],
      status: "ACTIVE",
      poId: [],
      lineId: []
    });
  }


  ngAfterViewInit() {
    if (this.parentForm) {
      this.productId = this.parentForm.controls['productId'].value
      this.getCatalogItem(this.productId).then(res => {
        this.loadAdditionalProductDetail();
      });

    }
    this.cdr.detectChanges();
  }

  loadAdditionalProductDetail() {
    let setFields = ['netWeight', 'grossWeight', 'origin', 'coo', 'weightUom', 'poId', 'id', 'lineId', 'priceByUom'];
    Object.keys(this.parentForm.controls).forEach(name => {
      if (this.productForm.controls[name]) {
        if (setFields.includes(name)) {
          if (name == 'netWeightUom' || name == 'weightUom')
            this.radioChange(this.parentForm.controls[name].value);
          else if (name == 'id')
            this.productForm.controls['lineId'].patchValue(this.parentForm.controls[name].value, '');
          else if (name == 'productId')
            this.productForm.controls['id'].patchValue(this.parentForm.controls[name].value, '');
          else
            this.productForm.controls[name].patchValue(this.parentForm.controls[name].value, '');
          this.productForm.controls[name].updateValueAndValidity();
        }
      }
    });
    // if (this.parentForm) {
    //   this.productForm.get('lineId').setValue(this.parentForm.get('id').value);
    //   this.productForm.get('netWeightUom').setValue(this.parentForm.get('weightUom').value);
    //   this.productForm.get('weightUom').setValue(this.parentForm.get('weightUom').value);
    //   this.productForm.get('id').setValue(this.parentForm.get('productId').value);
    //   this.productForm.get('poId').setValue(this.parentForm.get('poId').value);
    //   this.productForm.get('coo').setValue(this.parentForm.get('coo').value);
    //   this.productForm.get('coo').updateValueAndValidity();
    //   this.productForm.get('netWeight').setValue(this.parentForm.get('netWeight').value);
    //   this.productForm.get('netWeight').updateValueAndValidity();
    //   this.productForm.get('grossWeight').setValue(this.parentForm.get('grossWeight').value);
    //   this.productForm.get('grossWeight').updateValueAndValidity();
    // }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitLineChange() {
    this.submitEvent.emit(this.productForm);
  }

  //Send the data to Component
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  requiredFields = ['netWeight', 'grossWeight'];
  decimalCheckFields = ['netWeight', 'grossWeight'];
  pricekFields = ['basePrice'];
  isLoaded = false;

  //Intialize the code after the component created
  ngOnInit() {

    //this.getPrimaryAttributes();
    this.getDepartment();
    this.getPricePer();
    this.getGrowingMethod();
    this.getPackType();
    this.getOrigin();
    this.getUOM();
    this.getStatus();
    //this.getCatalogItem(this.productId);

  }



  dynamicFormField() {
    const group = <FormGroup>this.productForm;
    let attr = this.catalogAttributes.find(attr => attr.Attributes == 'Status')
    if (this.modalData != undefined && attr == undefined)
      this.catalogAttributes.push({ "Sr": 10, "Attributes": 'Status', "value": { value: 'ACTIVE', component: 'dropdwon', field: 'status' } });
    this.catalogAttributes.forEach(control => {
      group.addControl(control.value.field, this.setValidator(control.value.field))

    });
    this.dataSource = this.catalogAttributes;
    this.isLoaded = true;
    return group;

  }

  //Validator for dynamic fields
  setValidator(field): FormControl {
    var validatorArray = [];
    if (this.requiredFields.includes(field)) {
      validatorArray.push(Validators.required);
    }
    if (this.decimalCheckFields.includes(field)) {
      validatorArray.push(CommonServices.negativeNumberValidator);
      validatorArray.push(CommonServices.decimalNumberValidator);
      validatorArray.push(Validators.min(0));
      validatorArray.push(Validators.max(999.9999));
    }
    return this.fb.control('', validatorArray);
  }
  localUrl: any;
  editIcon: any = "icon-upload_file";
  getCatalogItem(id?) {
    return new Promise((resolve, reject) => {
      this.catalog.getProduct(id).subscribe(
        resultContent => {
          this.catalogItem = resultContent;
          if (this.catalogItem.thumbnail) {
            this.localUrl = "data:image/" + this.catalogItem.thumbnail.name.split('.').pop() + ";base64," + this.catalogItem.thumbnail.base64Content;
            this.editIcon = "icon-edit";
          }
          Object.keys(this.catalogItem).forEach(name => {
            if (this.productForm.controls[name]) {
              if (name == 'netWeightUom' || name == 'weightUom')
                this.radioChange(this.catalogItem[name]);
              else
                this.productForm.controls[name].patchValue(this.catalogItem[name], '');
            }
            else {
              if (name == 'categoryLevel1') {
                this.productForm.controls['department'].patchValue(this.catalogItem[name].name, '');
                this.getCommodity(this.catalogItem[name].parentCategoryId, true);
              }

              if (name == 'categoryLevel2')
                this.productForm.controls['commodity'].patchValue(this.catalogItem[name].name, '');
              if (name == 'origin' && !this.parentForm.controls['origin'])
                this.productForm.controls['coo'].patchValue(this.catalogItem[name], '');
              if (name == 'pricePer')
                this.productForm.controls['priceByUom'].patchValue(this.catalogItem[name], '');
            }
          });
          resolve(true);
        }

      );

    });
  }

  //netWeightUom,weightUom should be same
  radioChange(value: any) {
    this.productForm.controls['netWeightUom'].setValue(value);
    this.productForm.controls['weightUom'].setValue(value);
    this.uom = value;
  }

  //On click load dropdown values
  getDropDownOption(attr) {
    this.dropdown = this.optionsObj.find(obj => obj.type.toUpperCase() == attr.toUpperCase());
    return this.dropdown ? this.dropdown['attributes'] : [];
  }

  setValue(event, type) {
    this.dropdown = this.optionsObj.find(obj => obj.type.toUpperCase() == type.toUpperCase());
    let depart = this.dropdown['attributes'].filter(x => x.code === event.value);
    if (type == DropDownNames.DEPARTMENT) {
      this.getCommodity(depart[0].parentCategoryId, true);
    }
    this.productForm.get(type).setValue(depart[0].name);
  }

  //"Gross weight cannot be less than net weight."
  isGrossNetWeigtValid() {
    let grossWeight = parseFloat(this.productForm.get('grossWeight').value);
    let netWeight = parseFloat(this.productForm.get('netWeight').value);
    if (grossWeight <= netWeight && (grossWeight > 0 && netWeight > 0)) {
      return true;
    }
    return false;
  }
  getDepartment() {
    this.catalog.getDepartments().subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.DEPARTMENT;
        obj.attributes = resultContent;
        this.optionsObj.push(obj);
        //this.getCommodity(resultContent[0].parentCategoryId, false);
      }
    );
  }

  getCommodity(parentId, update?) {
    this.catalog.getCommodity(parentId).subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.COMMODITY;
        obj.attributes = resultContent;
        let options = this.optionsObj;
        let commodity = this.optionsObj.find(obj => obj.type.toUpperCase() == DropDownNames.COMMODITY.toUpperCase());

        if (commodity != undefined) {
          this.optionsObj.forEach((element, i) => {
            if (element.type === obj.type)
              element['attributes'] = resultContent;
          });
        } else
          this.optionsObj.push(obj);
      }
    );
  }

  getPackType() {
    this.catalog.getPackTypes().subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.PACKT_TYPE;
        obj.attributes = resultContent;
        this.optionsObj.push(obj);
      }
    );
  }

  getUOM() {
    this.catalog.getUOM().subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.UOM;
        obj.attributes = resultContent;
        this.optionsObj.push(obj);
      }
    );
    this.dynamicFormField();
  }

  getGrowingMethod() {
    this.catalog.getGrowingMethods().subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.GROWING_METHOD;
        obj.attributes = resultContent;
        this.optionsObj.push(obj);
      }
    );
  }

  getOrigin() {
    this.catalog.getCountryCodes().subscribe(
      resultContent => {
        let obj = new DropDownOptions();
        obj.type = DropDownNames.COO;
        obj.attributes = resultContent;
        this.optionsObj.push(obj);
      }
    );
  }

  getPricePer() {
    let obj = new DropDownOptions();
    obj.type = DropDownNames.PRICE_PER;
    obj.attributes = [
      {
        name: "Pack Type",
        code: "PACKTYPE"
      },
      {
        name: "Weight",
        code: "WEIGHT"
      }
    ];
    this.optionsObj.push(obj);
  }

  getStatus() {
    let obj = new DropDownOptions();
    obj.type = DropDownNames.STATUS;
    obj.attributes = [
      {
        name: "Active",
        code: "ACTIVE"
      },
      {
        name: "Inactive",
        code: "INACTIVE"
      }
    ];
    this.optionsObj.push(obj);
  }
}