import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommentsOverlayComponent } from '../comments-overlay/comments-overlay.component';

@Component({
  selector: 'comments-container',
  templateUrl: './comments-container.component.html',
  styleUrls: ['./comments-container.component.scss']
})
export class CommentsContainerComponent implements OnInit {

  @Input() commentData = [];
  @Input() showSendButton: boolean = true;
  @Input() docId: any;
  @Input() docTypeCode: any;
  @Input() rowData: any;
  @Input() commentValue: string;
  @Input() disableComment: boolean = false;
  @Output() setComment: EventEmitter<any> = new EventEmitter();
  validComment: boolean = false;

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  openComment() {
    this.rowData = 0;
    const dialogRef = this.dialog.open(CommentsOverlayComponent, {
      width: '550px',
      height: 'auto',
      data: { docId: this.docId, docTypeCode: this.docTypeCode, setComment: this.setComment, rowData: this.rowData, showSendButton: this.showSendButton, commentValue: this.commentValue },
      disableClose: true
    });
  }

}
