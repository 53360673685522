<mat-toolbar>
  <span class="logo"><img src="assets/images/procurant_logo.png" width="45"></span>
  <span class="logoText">{{ 'LOGIN.PROCURANT' | translate }}</span>
  <span class="example-spacer"></span>
</mat-toolbar>
<mat-divider></mat-divider>
<div fxLayout="row" fxLayoutAlign="center center" *ngIf="!showResetPasswordPage">
  <h2 fxLayoutAlign="center center">
    {{ 'LOGIN.WELCOME_TO_PROCURANT' | translate }}
    <span style="margin-left: 5px"> {{ userData.fname}}</span>
  </h2>
</div>
<div fxLayout="row" fxLayoutAlign="center center" *ngIf="showResetPasswordPage">
  <h2 fxLayoutAlign="center center">
    {{ 'COMPANY.LABEL.ENTER_NEW_PASSWORD' | translate }}
  </h2>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
  <div [formGroup]="user">
    <div class="fieldGroup horizontalGroup" style="padding-top: 20px;" *ngIf="!showResetPasswordPage">
      <span class="fieldLabel" style="margin-right: 20px">{{ 'COMPANY.LABEL.EMAIL' | translate }}:</span>
      <mat-form-field class="formContainer mt--18" fxLayoutAlign="end">
        <input matInput readonly formControlName="email" placeholder="">
      </mat-form-field>
    </div>

    <div class="fieldGroup horizontalGroup" style="padding-top: 20px;">
      <span class="fieldLabel" style="margin-right: 20px">{{ 'COMPANY.LABEL.NEW_PASSWORD' | translate }}*:</span>

      <mat-form-field class="formContainer mt--18" fxLayoutAlign="end">
        <input matInput formControlName="password" placeholder="" type="password" maxlength="64" autocomplete="off"
          matTooltip="{{ 'COMPANY.LABEL.PASSWORD_TOOLTIP' | translate }}" (keyup)="areMatch()" />
        <div style="margin-top:10px;">
          <mat-error *ngIf="user.get('password').touched && user.get('password').hasError('required')">
            {{ 'COMPANY.MESSAGE.PASSWORD_REQUIRED_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="user.get('password').touched && user.get('password').hasError('minlength')">
            {{ 'COMPANY.MESSAGE.PASSWORD_LENGTH_ERROR' | translate }}
          </mat-error>
          <mat-error *ngIf="!user.get('password').hasError('minlength') && user.get('password').hasError('pattern')">
            {{ 'COMPANY.MESSAGE.PASSWORD_VAILD_ERROR' | translate }}
          </mat-error>
        </div>
      </mat-form-field>

    </div>
    <div class="fieldGroup horizontalGroup" style="padding-top: 20px;">
      <span class="fieldLabel" style="margin-right: 20px">{{ 'COMPANY.LABEL.CONFORM_PASSWORD' | translate }}*:</span>
      <mat-form-field class="formContainer mt--18">
        <input matInput formControlName="confirmPassword" placeholder="" type="password" maxlength="64" autocomplete="off"
          matTooltip="{{ 'COMPANY.LABEL.PASSWORD_TOOLTIP' | translate }}" (keyup)="areMatch()" />
        <div style="margin-top:10px;">
          <mat-error *ngIf="user.get('confirmPassword').touched && user.get('confirmPassword').hasError('required')">
            {{ 'COMPANY.MESSAGE.CONFORM_PASSWORD_REQUIRED_ERROR' | translate }}
          </mat-error>
          <mat-error
            *ngIf="user.get('confirmPassword').touched && !user.get('confirmPassword').hasError('required') && isPasswordMatch">
            {{ 'COMPANY.MESSAGE.PASSWORD_MISSMATCH_ERROR' | translate }}
          </mat-error>
        </div>
      </mat-form-field>
    </div>

    <div style="margin-top: 40px">
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button class="btn btnSecondary" (click)="resetUser()">{{ 'BUTTON.RESET' |
          translate }}</button>
        <button mat-button class="btn btnPrimary" [disabled]="user.invalid || isPasswordMatch" (click)="create()">{{
          'BUTTON.SUBMIT' |
          translate
          }}</button>
      </div>
    </div>
  </div>
</div>