import { Component, OnInit } from '@angular/core';
import { IMAGES } from '../../../../../environments/config.all';
@Component({
  selector: 'app-procurant-loading',
  templateUrl: './procurant-loading.component.html',
  styleUrls: ['./procurant-loading.component.scss']
})
export class ProcurantLoadingComponent implements OnInit {

  constructor() { }
  public IMAGES = IMAGES;

  ngOnInit() {
  }

}
