import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lib-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

  hideCancel: boolean = false;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

  onOkClick() {
    return new Promise((resolve, reject) => {
      this.dialogRef.close({ result: true });
      resolve(true);
    });
  }

  onCancelClick() {
    return new Promise((resolve, reject) => {
      this.dialogRef.close({ result: false });
      resolve(false);
    });
  }

}