<div class="mat-elevation-z8 responsiveTable">
  <!-- Show & hide columns start -->
  <mat-toolbar color="#f2f4f7" *ngIf="showHideColumn">
    <mat-toolbar-row>
      <span class="fill-space"></span>
      <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false">
        <mat-list *ngFor="let col of displayedColumns; let i = index">
          <mat-list-item *ngIf="col.field!='select'" (click)="$event.stopPropagation();">
            <mat-checkbox class="example-margin" [checked]="true" [disabled]="i==1"
              (change)="showHideColumns($event,col.field,i)" [value]="col.field">{{col.title}}
            </mat-checkbox>
          </mat-list-item>
        </mat-list>
      </mat-menu>
    </mat-toolbar-row>
  </mat-toolbar>
  <div>
    <table id="doneList" cdkDropList cdkDropListConnectedTo="todoList" (cdkDropListDropped)="drop($event)" class="table"
      mat-table #table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)" aria-label="Elements">
      <ng-container [matColumnDef]="cols.field" *ngFor="let cols of displayedColumns;let j=index"
        [sticky]="isSticky(cols)">

        <ng-container *ngIf="cols.field == 'drag'">
          <!-- Header level Drag start -->
          <th mat-header-cell *matHeaderCellDef></th>
          <!-- Header level Drag end -->
          <!-- Row level Drag start -->
          <td mat-cell mat-cell *matCellDef="let row">
            <mat-icon cdkDragHandle style="pointer-events: all;cursor: grab;" class="handler"><span
                class="icon2-drag fs-18"></span></mat-icon>
          </td>
          <!-- Row level Drag end -->
        </ng-container>
        <!-- Checkbox column start -->
        <ng-container *ngIf="cols.field == 'select'">
          <!-- Header level checkbox start -->
          <th mat-header-cell *matHeaderCellDef>
            <ng-container *ngIf="headerCheckBox">
              <mat-checkbox (change)="$event ? masterToggle($event.checked) : null" (mouseenter)="preventDrag()"
                (mouseleave)="startDrag()" style="pointer-events: all;"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </ng-container>
          </th>
          <!-- Header level checkbox end -->
          <!-- Row level checkbox start -->
          <td mat-cell mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (mouseenter)="preventDrag()" (mouseleave)="startDrag()"
              style="pointer-events: all;" (change)="$event ? isSelected(row, $event.checked) : null"
              [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </td>
          <!-- Row level checkbox end -->
        </ng-container>
        <!-- Checkbox column end -->
        <!-- Dynamic actions or buttons columns start -->
        <ng-container *ngIf="cols.field=='actions'">
          <th mat-header-cell *matHeaderCellDef> {{cols.title | translate}} </th>
          <td mat-cell *matCellDef="let row;let index = index">
            <ng-container *ngFor="let btn of actionButtonsArray">
              <ng-container *ngIf="btn.action != commentButton">
                <button mat-button class="roundBtn" (mouseenter)="preventDrag()" (mouseleave)="startDrag()"
                  style="pointer-events: all;" [disabled]="btn.isDisabled" (click)="actionEvent(btn.action,row)">
                  <mat-icon class="vAlignTop" matTooltip="{{getMatToolTipForIcon(btn,row)}}"
                    (mouseenter)="preventDrag()" (mouseleave)="startDrag()"><span class="{{btn.img}}"
                      (mouseenter)="preventDrag()" (mouseleave)="startDrag()"></span></mat-icon>
                </button>
              </ng-container>
              <!-- Comments action start -->
              <ng-container *ngIf="btn.action == commentButton">
                <comments-container [showSendButton]="true" style="pointer-events: all;" [docTypeCode]="btn.type"
                  [docId]="row.id" [rowData]="row.commentCount" [disableComment]="btn.isDisabled"
                  (setComment)="setComment($event,index)" [commentValue]="row.comment" (mouseenter)="preventDrag()"
                  (mouseleave)="startDrag()"></comments-container>
              </ng-container>
              <!-- Comments action end -->
            </ng-container>
          </td>
        </ng-container>
        <!-- Dynamic actions or buttons columns end -->
        <th #header mat-header-cell *matHeaderCellDef mat-sort-header class="{{cols.cssClass}}"
          (mousedown)="onMouseDown1($event,j)" (mouseup)="onMouseup1($event,j)"> {{cols.title | translate}}
          <span class="ui-column-resizer" (mousedown)="onMouseDown($event,j)"></span>
        </th>
        <td mat-cell *matCellDef="let element" class="{{cols.cssClass}}"
          [ngClass]="{'campiarIndicator':element[cols.field]!=element[cols.compairColumn] && cols.compair}"
          (mouseenter)="preventDrag()" (mouseleave)="startDrag()">


          <span *ngIf="!cols.url && !cols.parseFormat && !cols.currancyFormat && !cols.dropdownData && !cols.tooltip">
            {{element[cols.field]}} </span>
          <span style="float: right;margin-top: 9px;" *ngIf="cols.isSubStatus"
            [ngClass]="{'otherss-modify':element.subStatusIcon=='R','selfs-modify':element.subStatusIcon=='B','':element.subStatusIcon==''}"
            matTooltip="{{element.subStatustooltip}}">
          </span>

          <!-- Tooltip on columns -->
          <span *ngIf="cols.tooltip" (mouseover)="getTooltip(element)"
            matTooltip="{{rowtooltipData}}">{{element[cols.field]}}</span>

          <!-- Url Format for columns -->
          <a *ngIf="cols.url && linkClass=='tableLinks'" href="javascript:void(0);"
            (click)="urlToNavigate(cols,element)" class="{{linkClass}}"> {{element[cols.field]}} </a>
          <a *ngIf="cols.url && linkClass!='tableLinks'" (click)="urlToNavigate(cols,element)" class="{{linkClass}}">
            {{element[cols.field]}} </a>
          <!-- Date Format for columns -->
          <span *ngIf="cols.parseFormat"> {{element[cols.field]|pipes:'date'}} </span>

          <!-- Currency Format for columns -->
          <span *ngIf="cols.currancyFormat" class="Aright">
            {{element[cols.field]!=0?(element[cols.field]|pipes:'currancy'):element[cols.field]}} </span>

          <!-- Dropdown for columns -->
          <span *ngIf="cols.dropdownData">
            <mat-form-field floatLabel="never">
              <mat-select [disabled]="element[cols.isAllowDisabled]" placeholder="{{'COMPANY.LABEL.NAME|translate'}}"
                [(value)]="element[cols.field]" (selectionChange)="selectedDropdown($event.value,cols.field,element)"
                disableOptionCentering>
                <mat-option *ngFor="let drop of cols.dropdownData" [value]="drop.value">{{drop.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="dataFileds;"></tr>
      <tr mat-row *matRowDef="let row; columns: dataFileds;" class="drag" cdkDrag [cdkDragData]="row"
        [cdkDragDisabled]="checkAllUsnselcted(selection.isSelected(row))" (cdkDragStarted)="dragStarted($event)"
        (cdkDragEnded)="dragEnded($event)" (cdkDragMoved)="dragMoved($event)">
        <div *cdkDragPreview matBadge="8" matBadgePosition="before" matBadgeColor="accent">
          <div class="dragable-container" *ngIf="selectedRows.length>0">
            <div class="draggingDiv" *ngFor="let item of selectedRows;let i=index"
              [ngStyle]="{'margin-left':setMargin(i),'z-index':i}">
              <table style="width:100%">
                <tr>
                  <td>
                    <mat-checkbox [checked]="true">
                    </mat-checkbox>
                  </td>
                  <td>{{item.poNumber}}</td>
                  <td>{{item.sellerCompanyName}}</td>
                  <td>{{item.deliverToLocName}}</td>
                  <td>{{item.deliveryDate|pipes:'date'}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="dragable-container" *ngIf="selectedRows.length==0">
            <div class="draggingDiv">
              <table style="width:100%">
                <tr>
                  <td>
                    <mat-checkbox [checked]="true">
                    </mat-checkbox>
                  </td>
                  <td>{{row.poNumber}}</td>
                  <td>{{row.sellerCompanyName}}</td>
                  <td>{{row.deliverToLocName}}</td>
                  <td>{{row.deliveryDate|pipes:'date'}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </tr>
    </table>
    <span>
      <mat-card *ngIf="dataSource.data.length == 0" style="padding:50px;">
        <h3 style="text-align:center"> {{noDataMessage | translate}} </h3>
      </mat-card>
      <ng-container *ngIf="pagerConfig">
        <mat-paginator #paginator style="margin-bottom: 40px;" [length]="pagerConfig.totalItems"
          [pageIndex]="pagerConfig.pageIndex" [pageSize]="pagerConfig.pageSize" [pageSizeOptions]="pageSizeOptions"
          (page)="onPageChange($event)" [showFirstLastButtons]="showFirstLastButtons" disableOptionCentering>
        </mat-paginator>
      </ng-container>
    </span>
    <span id="todoList"></span>
  </div>

  <!-- Table end -->
  <!-- No data message -->

</div>