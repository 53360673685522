import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Element } from '@angular/compiler';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})

export class DataGridComponent implements OnInit {
  @ViewChild('table') table: MatTable<any>;
  constructor(private hCell: ElementRef, public renderer: Renderer2, private translate: TranslateService) { }

  ngOnInit() {
    if (this.pagerConfig != undefined) {
      this.sort.sort(<MatSortable>{
        id: this.pagerConfig.sortBy,
        start: this.pagerConfig.direction
      });
    }
  }

  ngAfterViewInit() {

  }
  dataFileds: any;
  pageEvent: PageEvent;
  pageObject: any;
  start: Element;
  startX: number;
  pressed: boolean;
  startWidth: number;
  dataSource: any;
  currentIndex: number;
  randomIndex: number;
  public selectedCheckboxes = [];
  public selectedRows = [];
  displayedColumns: Array<string>;
  selection = new SelectionModel<any>(true, []);
  // commentButton = ActionButtons.ACTION_COMMENT;
  commentButton = "comment";
  dragging: boolean = true;
  allowDrag: boolean = true;
  isPointerOverContainer: boolean;
  @Input() noDataMessage: string = 'ORDER_LIST.MESSAGE.NO_RECORD_ERROR';
  @Input() public pagerConfig: any;
  @Input() public sortArgs: any;
  @Input() public pageSizeOptions: any = [10, 20, 30, 50]; // Default page size options
  @Input() headerCheckBox: boolean;
  //@Input() isDragable: boolean;
  @Input() actions: boolean;
  @Input() isSubStatus: boolean = false;
  @Input() showFirstLastButtons: boolean = true;
  @Input() set data(_data: any[]) {
    this.selection.clear();
    this.selectedCheckboxes = [];
    this.selectedRows = [];
    this.dataSource = new MatTableDataSource(_data);
  }
  @Input() id: string;
  @Input() isRow: boolean = false;
  @Input() set columns(_columns: any[]) {
    var header = [];
    var fileds = [];
    _columns.forEach(element => {
      header.push(element);
      fileds.push(element.field);
    });
    this.displayedColumns = header;
    this.dataFileds = fileds;
  }
  @Input() actionButtonsArray: any;
  @Input() showHideColumn: boolean = false;
  @Input() rowtooltipData: string;
  @Input() linkClass: string = 'tableLinks';

  @Output() onPageChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSortChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowSelection: EventEmitter<any> = new EventEmitter();
  @Output() select: EventEmitter<any> = new EventEmitter();
  @Output() toNavigate: EventEmitter<any> = new EventEmitter();
  @Output() actionEvents: EventEmitter<any> = new EventEmitter();
  @Output() dropdownSelect: EventEmitter<any> = new EventEmitter();
  @Output() gettootltipData: EventEmitter<any> = new EventEmitter();
  @Output() dropEmit: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('header') header: ElementRef;

  setComment(event, index) {
    this.dataSource.filteredData[index].comment = event;
  }
  getTooltip(row) {
    this.gettootltipData.emit(row);
  }

  getMatToolTipForIcon(btn, row){
    return btn.action =="info" && btn.name=="DYNAMIC" ? row.userInfo : this.translate.instant(btn.name);
  }

  // public getPaginatorIntl(): MatPaginatorIntl {
  //   const paginatorIntl = new MatPaginatorIntl();
  //   paginatorIntl.itemsPerPageLabel = 'Onkar Lonkar';

  //   return paginatorIntl;
  // }

  /* Page configuration object */
  setPageObject(pageIndex?, pageSize?, sortBy?, direction?) {
    this.pageObject = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      direction: direction
    }
  }

  /* Dynamic dropdown selection in column*/
  selectedDropdown(event, col, row) {
    let obj = {
      "event": event,
      "col": col,
      "row": row
    }
    this.dropdownSelect.emit(obj);
  }

  /* URL in column to navigate */
  urlToNavigate(col, row) {
    this.toNavigate.emit({ col: col, row: row });
  }

  /* Dynamic action buttons click event */
  actionEvent(action, rowData) {
    let event = { action: action, rowData: rowData }
    this.actionEvents.emit(event);
  }

  /* Sorting change event */
  onSortChange(event) {
    this.masterToggle(false);
    this.setPageObject(this.pagerConfig.pageIndex, this.pagerConfig.pageSize, event.active, event.direction);
    this.onPageAction(this.pageObject);
  }

  /* Page change event */
  onPageChange(event) {
    this.masterToggle(false);
    this.setPageObject(event.pageIndex, event.pageSize, this.pagerConfig.sortBy, this.pagerConfig.direction);
    this.onPageAction(this.pageObject);
  }

  onPageNumberChange(index: number) {
    this.paginator.pageIndex = index - 1;
    this.setPageObject(this.paginator.pageIndex, this.pagerConfig.pageSize, this.pagerConfig.sortBy, this.pagerConfig.direction);
    this.onPageAction(this.pageObject);
  }

  /* emit both page change & sort change event */
  onPageAction(event) {
    this.onPageChanged.emit(event);
  }

  /* Sticky columns */
  isSticky(column) {
    return column.freez === true ? true : false;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /* Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(checked: boolean) {
    this.selectedCheckboxes = [];
    if (checked) {
      this.dataSource.data.forEach(row => {
        this.selection.select(row);
        if (this.isRow)
          this.selectedRows.push(row);

        this.selectedCheckboxes.push(row[this.id]);
      });
    } else {
      this.dataSource.data.forEach(row => {
        this.selection.clear();
        if (this.isRow)
          this.selectedRows.splice(this.selectedRows.indexOf(row), 1)

        this.selectedCheckboxes.splice(this.selectedCheckboxes.indexOf(row[this.id]), 1)
      });
    }
    this.selectAll(this.selectedCheckboxes, this.selectedRows);
  }

  checkAllUsnselcted(row) {
    if (this.allowDrag) {
      if (this.selectedRows.length > 0 && !row) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  /* Single row selections */
  isSelected(row: any, checked: boolean) {
    this.selection.toggle(row);
    if (checked) {
      if (this.isRow)
        this.selectedRows.push(row);

      this.selectedCheckboxes.push(row[this.id]);
    } else {
      if (this.isRow)
        this.selectedRows.splice(this.selectedRows.indexOf(row), 1);

      this.selectedCheckboxes.splice(this.selectedCheckboxes.indexOf(row[this.id]), 1);
    }
    this.selectAll(this.selectedCheckboxes, this.selectedRows);
  }

  /* Select all checkboxes */
  selectAll(selectedCheckboxes, selectedRows?) {
    if (this.isRow) {
      let obj = {
        "selectedRows": selectedRows,
        "selectedCheckboxes": selectedCheckboxes,
      }
      this.rowSelection.emit(obj);
    }
    else {
      this.rowSelection.emit(selectedCheckboxes);
    }
  }

  onMouseDown1(event, i) {
    this.currentIndex = i;
  }

  onMouseup1(event, i) {
    this.randomIndex = i;
    this.shuffle(this.randomIndex);
  }

  shuffle(i) {
    let randomIndex = i;
    let temp = this.dataFileds[this.currentIndex];
    this.dataFileds[this.currentIndex] = this.dataFileds[randomIndex];
    this.dataFileds[randomIndex] = temp;
    let temp1 = this.displayedColumns[this.currentIndex];
    this.displayedColumns[this.currentIndex] = this.displayedColumns[randomIndex];
    this.displayedColumns[randomIndex] = temp1;
  }

  onMouseDown(event, index) {
    this.start = event.target;
    this.pressed = true;
    this.startX = event.x;
    this.startWidth = event.target.offsetParent.offsetWidth;
    this.initResizableColumns(event.target.offsetParent.cellIndex);
  }

  initResizableColumns(index) {
    this.renderer.listen('body', 'mousemove', (event) => {
    if (this.pressed) {
        let width = this.startWidth + (event.x - this.startX);
        let colHeaderEle = this.hCell.nativeElement.querySelector("thead>tr").children[index + 1];
        colHeaderEle.style.width = width + 'px';
        let colDataEle = this.hCell.nativeElement.querySelector("tbody>tr").children[index + 1];
        colDataEle.style.width = width + 'px';
    }
});
    this.renderer.listen('body', 'mouseup', (event) => {
    if (this.pressed) {
        this.pressed = false;
    }
});
  }

  /* Show or hide columns */
  showHideColumns(event, colName, colIndex) {
    if (event.checked) {
      this.dataFileds.splice(colIndex, 0, colName);
    } else {
      colIndex = this.dataFileds.findIndex(col => col === colName);
      this.dataFileds.splice(colIndex, 1);
    }
  }

  done = [{
    label: 'demo'
  }]

  /****************************************** Drag n Drop**************************** */
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container && this.selectedRows instanceof Array) {
      moveItemInArray(this.selectedRows, event.previousIndex, event.currentIndex);
      this.table.renderRows();
    } else {
      /*    console.log("event.container.data,==",event.container.data)
         if(this.selectedRows instanceof Array)
         transferArrayItem(event.previousContainer.data,
                           this.selectedRows,
                           event.previousIndex,
                           event.currentIndex); */
    }
  }

  setMargin(i) {
    return (i * 5) + 'px'
  }

  dragDropped(event: CdkDragDrop<string[]>) {
    this.isPointerOverContainer = event.isPointerOverContainer;
  }

  entered(event) { }

  moving(event) { }

  dragEnded(event) { }

  dragMoved(event) {
    window.scrollBy(event.delta.x, event.delta.y);
  }
  dragStarted(event) { }

  preventDrag() {
    this.allowDrag = false;
  }

  startDrag() {
    this.allowDrag = true;
  }

}
