<div fxLayout="row" class="popupHead">
    <div fxLayout="column">
    <h1 mat-dialog-title>{{'LABEL.COMMENTS' | translate }}</h1>
    </div>
    <span style="flex: 1 1 auto;"> </span>
    <div fxLayout="column">
      <a style="min-width: unset;cursor: pointer;" (click)="onNoClick()">
        <mat-icon>close</mat-icon>
      </a>
    </div>
  </div>
  <div mat-dialog-content style="height: 300px;" #scrollMe>
        <div class="commentContent">
          <div *ngFor="let comm of commentData">
            <div class="receiverContent" *ngIf="orgId!=comm.orgId">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <span class="fs-16 mBold mr-15">{{comm.fname}} {{comm.lname}}</span>
                    <span class="fs-12 lightText">{{comm.createdTs|pipes:'date':'h:mm a MM/dd/yyyy '}}</span>
                  </div>
                </div>
                <div class="paragraph">
                    {{comm.comment}}
                </div>
            </div>
            <div class="senderContent" *ngIf="orgId==comm.orgId">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <div>
                    <span class="fs-16 mBold mr-15">{{comm.fname}} {{comm.lname}}</span>
                    <span class="fs-12 lightText">{{comm.createdTs|pipes:'date':'h:mm a MM/dd/yyyy '}}</span>
                  </div>
                </div>
                <div class="paragraph">
                    {{comm.comment}}
                </div>
            </div>
          </div>
        </div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center" class="commentBoxActions">
    <input type="text" placeholder="{{'TABLE_DISPLAY_COLUMNS.TYPE_COMMENT' | translate }}" #comment matInput maxlength="255" [(ngModel)]="searchValue" (keyup)="setCommentValue($event.target.value)" (keyup.enter)="addComment(data.docTypeCode,data.docId,$event)" (paste)="onPaste($event.clipboardData.getData('text/plain'))"/>
    <button mat-button class="btnPrimary" *ngIf="data.showSendButton" [disabled]="!validComment" (click)="addComment(data.docTypeCode,data.docId,$event)"><span class="icon2-send fs-24"></span></button>
  </div>